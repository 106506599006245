export const IconGondolaCold = () => {
    return (
        <svg width="102" height="143" viewBox="0 0 102 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_254_62)">
                <path d="M94.2883 142.941H7.71908C3.93349 142.941 0.848633 139.864 0.848633 136.07V7.81137C0.848633 4.01772 3.92543 0.940918 7.71908 0.940918H94.2883C98.0739 0.940918 101.159 4.01772 101.159 7.81137V136.079C101.159 139.864 98.082 142.949 94.2883 142.949V142.941ZM7.71908 3.35725C5.26247 3.35725 3.26497 5.35476 3.26497 7.81137V136.079C3.26497 138.535 5.26247 140.533 7.71908 140.533H94.2883C96.7449 140.533 98.7424 138.535 98.7424 136.079V7.81137C98.7424 5.35476 96.7449 3.35725 94.2883 3.35725H7.71908Z" fill="#183555" />
                <path d="M98.7905 56.412H0.904785V58.8283H98.7905V56.412Z" fill="#183555" />
                <path d="M98.7905 68.405H0.904785V70.8214H98.7905V68.405Z" fill="#183555" />
                <path d="M27.0418 58.3773H15.3145C13.462 58.3773 11.9478 56.7422 11.9478 54.7366V33.7951C11.9478 31.129 13.9372 28.9543 16.3777 28.9543H17.4167V15.4551C17.4167 13.9731 18.5444 12.7729 19.9297 12.7729H22.4185C23.8039 12.7729 24.9315 13.9731 24.9315 15.4551V28.9543H25.9706C28.4111 28.9543 30.4005 31.129 30.4005 33.7951V54.7366C30.4005 56.7422 28.8943 58.3773 27.0337 58.3773H27.0418ZM16.3777 31.3707C15.2662 31.3707 14.3641 32.458 14.3641 33.7951V54.7366C14.3641 55.3971 14.799 55.9609 15.3145 55.9609H27.0418C27.5573 55.9609 27.9922 55.3971 27.9922 54.7366V33.7951C27.9922 32.458 27.0901 31.3707 25.9786 31.3707H22.5232V15.4551C22.5232 15.2859 22.4427 15.1973 22.4105 15.1893H19.9297C19.9297 15.1893 19.8331 15.2859 19.8331 15.4551V31.3707H16.3777Z" fill="#183555" />
                <path d="M57.077 58.3773H45.3497C43.4971 58.3773 41.9829 56.7422 41.9829 54.7366V33.7951C41.9829 31.129 43.9724 28.9543 46.4129 28.9543H47.4519V15.4551C47.4519 13.9731 48.5795 12.7729 49.9649 12.7729H52.4537C53.8391 12.7729 54.9667 13.9731 54.9667 15.4551V28.9543H56.0057C58.4462 28.9543 60.4357 31.129 60.4357 33.7951V54.7366C60.4357 56.7422 58.9295 58.3773 57.0689 58.3773H57.077ZM46.4129 31.3707C45.3013 31.3707 44.3992 32.458 44.3992 33.7951V54.7366C44.3992 55.3971 44.8342 55.9609 45.3497 55.9609H57.077C57.5924 55.9609 58.0274 55.3971 58.0274 54.7366V33.7951C58.0274 32.458 57.1253 31.3707 56.0138 31.3707H52.5584V15.4551C52.5584 15.2859 52.4779 15.1973 52.4456 15.1893H49.9649C49.9649 15.1893 49.8682 15.2859 49.8682 15.4551V31.3707H46.4129Z" fill="#183555" />
                <path d="M87.1199 58.3773H75.3926C73.5401 58.3773 72.0259 56.7422 72.0259 54.7366V33.7951C72.0259 31.129 74.0153 28.9543 76.4558 28.9543H77.4949V15.4551C77.4949 13.9731 78.6225 12.7729 80.0078 12.7729H82.4967C83.882 12.7729 85.0097 13.9731 85.0097 15.4551V28.9543H86.0487C88.4892 28.9543 90.4786 31.129 90.4786 33.7951V54.7366C90.4786 56.7422 88.9724 58.3773 87.1119 58.3773H87.1199ZM76.4558 31.3707C75.3443 31.3707 74.4422 32.458 74.4422 33.7951V54.7366C74.4422 55.3971 74.8772 55.9609 75.3926 55.9609H87.1199C87.6354 55.9609 88.0703 55.3971 88.0703 54.7366V33.7951C88.0703 32.458 87.1682 31.3707 86.0567 31.3707H82.6014V15.4551C82.6014 15.2859 82.5208 15.1973 82.4886 15.1893H80.0078C80.0078 15.1893 79.9112 15.2859 79.9112 15.4551V31.3707H76.4558Z" fill="#183555" />
                <path d="M52.3006 74.5586H49.8843V93.6638H52.3006V74.5586Z" fill="#183555" />
                <path d="M58.7312 80.1688L50.5776 85.2836L51.8617 87.3305L60.0153 82.2158L58.7312 80.1688Z" fill="#183555" />
                <path d="M43.3337 80.1768L42.0493 82.2235L50.202 87.3397L51.4864 85.293L43.3337 80.1768Z" fill="#183555" />
                <path d="M52.3006 115.588H49.8843V134.693H52.3006V115.588Z" fill="#183555" />
                <path d="M51.8587 121.908L50.5747 123.955L58.7283 129.07L60.0124 127.023L51.8587 121.908Z" fill="#183555" />
                <path d="M50.2098 121.914L42.0562 127.029L43.3402 129.075L51.4938 123.961L50.2098 121.914Z" fill="#183555" />
                <path d="M40.8875 108.97L24.312 118.47L25.5136 120.567L42.0891 111.066L40.8875 108.97Z" fill="#183555" />
                <path d="M27.1231 107.979L25.9854 110.111L34.4767 114.643L35.6144 112.511L27.1231 107.979Z" fill="#183555" />
                <path d="M34.0164 113.841L33.6318 123.458L36.0462 123.555L36.4308 113.937L34.0164 113.841Z" fill="#183555" />
                <path d="M76.4783 88.5744L59.9028 98.075L61.1044 100.171L77.6799 90.6708L76.4783 88.5744Z" fill="#183555" />
                <path d="M66.0042 85.6948L65.6196 95.3121L68.034 95.4087L68.4186 85.7913L66.0042 85.6948Z" fill="#183555" />
                <path d="M67.584 94.6328L66.4463 96.7645L74.9376 101.297L76.0754 99.1649L67.584 94.6328Z" fill="#183555" />
                <path d="M26.6439 87.0102L25.3105 89.0254L41.2439 99.5675L42.5772 97.5523L26.6439 87.0102Z" fill="#183555" />
                <path d="M34.8646 84.7464L34.6328 94.3687L37.0484 94.4268L37.2803 84.8046L34.8646 84.7464Z" fill="#183555" />
                <path d="M35.141 93.5994L26.377 97.5786L27.3759 99.7788L36.1399 95.7996L35.141 93.5994Z" fill="#183555" />
                <path d="M60.8553 109.641L59.522 111.657L75.4553 122.199L76.7887 120.183L60.8553 109.641Z" fill="#183555" />
                <path d="M74.6517 109.53L65.8877 113.509L66.8867 115.71L75.6507 111.73L74.6517 109.53Z" fill="#183555" />
                <path d="M64.9798 114.888L64.748 124.51L67.1637 124.568L67.3955 114.946L64.9798 114.888Z" fill="#183555" />
                <path d="M51.2134 116.981L40.5332 110.812V98.4803L51.2134 92.3105L61.8936 98.4803V110.812L51.2134 116.981ZM42.9495 109.418L51.2134 114.186L59.4773 109.418V99.8737L51.2134 95.1054L42.9495 99.8737V109.418Z" fill="#183555" />
            </g>
            <defs>
                <clipPath id="clip0_254_62">
                    <rect width="100.302" height="142" fill="white" transform="translate(0.848633 0.940918)" />
                </clipPath>
            </defs>
        </svg>
    )
}