import { IonIcon } from "@ionic/react";
import { eye, eyeOff } from 'ionicons/icons';
import { useState } from "react";

// Descripción de las props
// 1. name: atributo name del input
// 2. label: label que acompaña al input
// 3. value: valor mostrado en el input
// 4. changeHandler: función que se ejecuta cada vez que se hace un cambio en el input
export const PasswordField = (
    {   name, 
        label, 
        value,
        changeHandler, 
        isFieldValid,
        errorMessage,
        required = false,
        placeholder,
        confirmPasswordValue,
    }: 
    {   name: string, 
        label: string, 
        value: string, 
        changeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
        isFieldValid?: boolean,
        formSubmitted?: boolean,
        errorMessage?: string,
        required?: boolean,
        placeholder?: string,
        confirmPasswordValue?: string,
    }
) => {
    const [inputChanged, setInputChanged] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const showPasswordClickHandler = () => {
        setShowPassword(!showPassword);
    }

    return (
        <div className="w-full flex flex-col gap-2">
            <div className="w-full flex items-end">
                    <label htmlFor={name} className="font-semibold text-mid text-medium">
                        {label}
                        {
                            required &&
                                <span className="font-bold text-danger"> *</span>
                        }
                    </label>
            </div>

            <div className="w-full flex items-center relative">
                <input 
                    type={showPassword ? "text" : "password"}
                    name={name}
                    id={name}
                    value={value}
                    placeholder={placeholder}
                    onChange={changeHandler}
                    onInput={() => setInputChanged(true)}
                    className={`w-full text-mid dark:bg-color-toolbar dark:text-light px-2 py-[6px] rounded-md border border-solid focus:border-primary dark:border-light focus:outline-none focus:border-2 ${(!inputChanged || isFieldValid === undefined) && 'border-tertiary focus:border-primary'} ${isFieldValid !== undefined && inputChanged && !!isFieldValid && 'border-secondary focus:border-secondary'} ${isFieldValid !== undefined && inputChanged && !isFieldValid && 'border-success focus:border-success'}`}
                />
                <IonIcon onClick={showPasswordClickHandler} icon={showPassword ? eyeOff : eye} className='absolute right-[10px] text-[20px] cursor-pointer dark:text-light' />
            </div>
            {
                isFieldValid && !!isFieldValid && inputChanged && <p className="w-full text-danger font-semibold text-mid">{errorMessage}</p>
            }

            {
                confirmPasswordValue && inputChanged && (value !== confirmPasswordValue) && !isFieldValid && <p className="w-full text-danger font-semibold text-mid">Las contraseñas no coinciden</p>
            }
        </div>
    )
}