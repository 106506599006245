import { IonIcon } from "@ionic/react"
import { InformativeModalType } from "types";
import { alertCircle, checkmarkCircle, informationCircle } from "ionicons/icons"

export const InformativeModal = (
    { 
        type, 
        title, 
        text, 
        acceptClickHandler, 
        acceptButtonText,
        disabledButton 
    }:
        {
             type: InformativeModalType, 
             title?: string, 
             text?: string, 
             acceptClickHandler: () => void, 
             acceptButtonText?: string,
             disabledButton?: boolean, 
        }
) => {
    return (
        <div className='w-screen h-screen fixed flex justify-center items-center z-40'>
            <div className="bg-white w-1/4 flex flex-col items-center px-4 pt-4 pb-8 rounded-md">
                {
                    type === 'success' &&
                    <IonIcon icon={checkmarkCircle} className="text-[80px] text-success" />
                }

                {
                    type === 'warning' &&
                    <IonIcon icon={alertCircle} className="text-[70px] text-warning" />
                }

                {
                    type === 'informative' &&
                    <IonIcon icon={informationCircle} className="text-[60px] text-blue-info" />
                }

                <div className="flex flex-col mt-4 gap-4">
                    {
                        title &&
                        <div className={`w-full px-4`}>
                            <p className='font-bold text-center text-[0.9rem]'>{title}</p>
                        </div>
                    }

                    {
                        text &&
                        <div className={`w-full px-4`}>
                            <p className='text-center text-[0.85rem]'>{text}</p>
                        </div>
                    }

                    <div className="w-full flex justify-center">
                        <button
                            onClick={acceptClickHandler}
                            disabled={disabledButton ?? false}
                            className={`bg-primary text-light font-bold text-[0.85rem] px-4 py-2 rounded-md w-[80%] ${disabledButton && "opacity-70"}`}
                        >
                            {acceptButtonText ?? "Aceptar"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}