import EmptyFolder from 'assets/img/app/empty-folder.png';
import { IonContent, IonDatetime, IonIcon, IonPage, IonSpinner } from "@ionic/react";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Pagination } from "@mui/material";
import { ImageVisualizer, LoadingPage, ModalBackground, SidebarMenu, SolidButton } from "components";
import { FirebaseDB } from "firebase-config";
import { collection, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore";
import { GondolaColdRecord, SearchFilterItem, SearchFilters, SucursalRecord } from "interfaces";
import { useEffect, useRef, useState } from "react"
import { convertTimestampToDate, formatDateToISODateString, formatDateToLocaleDateString, formatISODateStringToDate, formatTimestampToLocaleDate, getDocumentById, getSearchFilters, getSearchFiltersValues } from "utils";
import { caretDown } from 'ionicons/icons';

type GondolaColdFilters = "userId" | "nameSucursal";

const initialSearchFilters: Record<GondolaColdFilters, SearchFilters> = {
    userId: {
        allChecked: true,
        allHidden: false,
        items: [],
    },
    nameSucursal: {
        allChecked: true,
        allHidden: false,
        items: []
    },
}

export const GondolaColdPage = () => {
    const today = new Date();
    const todayAux = new Date();

    // estado para almacenar los benchmarks recuperados
    const [benchmarks, setBenchmarks] = useState<any[]>([]);
    const [filteredBenchmarks, setFilteredBenchmarks] = useState<any[]>([]);

    // estados para almacenar los datos del reporte
    const [report, setReport] = useState<SucursalRecord[][]>([]);
    const [visibleReport, setVisibleReport] = useState<SucursalRecord[]>([]);

    // estados para manejar la paginación
    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(10);

    // estados para manejar las pantallas de carga
    const [filteringReport, setFilteringReport] = useState<boolean>(false);
    const [searchingBenchmark, setSearchingBenchmark] = useState<boolean>(false);

    // estado que almacena el inicio del rango de fechas de los benchmarks consultadas
    const [startDate, setStartDate] = useState<Date>(new Date(todayAux.setDate(todayAux.getDate() - 30)));

    // estado que almacena el fin del rango de fechas de los benchmarks consultadas
    const [finishDate, setFinishDate] = useState<Date>(new Date());

    // estado que almacena la fecha de inicio escogida en el calendario de los filtros de búsqueda
    const [startDateSearchFilter, setStartDateSearchFilter] = useState<Date>(new Date(startDate));

    // estado que almacena la fecha de fin escogida en el calendario de los filtros de búsqueda
    const [finishDateSearchFilter, setFinishDateSearchFilter] = useState<Date>(new Date());

    // estado para saber si el rango de fecha de consulta de los benchmarks ha cambiado
    // para asegurar que el estado sea único se almacenará la fecha y la hora en la que se modifica dicho rango
    const [hasDatesChanged, setHasDatesChanged] = useState<number>(1);

    // estado para almacenar el arreglo de imágenes que se mostrarán en pantalla
    const [imagesToShow, setImagesToShow] = useState<string[]>([]);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);

    // estados para almacenar los valores que aparecerán en los filtros de búsqueda
    const [searchFilters, setSearchFilters] = useState<Record<GondolaColdFilters, SearchFilters>>(initialSearchFilters);

    const contentRef = useRef<HTMLIonContentElement>(null);

    const getBenchmarkDocs = async () => {
        try {
            setSearchingBenchmark(true);

            const benchmarkRef = collection(FirebaseDB, "benchmarks");

            const reportQuery = query(
                benchmarkRef,
                where("test", "==", false),
                where("created_at", ">=", startDate),
                where("created_at", "<=", finishDate),
                orderBy("created_at", "desc")
            );

            const reportSnapshot = await getDocs(reportQuery);

            if (reportSnapshot.size > 0) {
                setPaginationCount(Math.ceil(reportSnapshot.size / recordsPerView));
                const benchmarkReport = reportSnapshot.docs.map((doc) => doc.data());
                setBenchmarks(benchmarkReport);
                setFilteredBenchmarks(benchmarkReport);

                // Obtenemos los valores de los filtros de búsqueda
                let displaysArray = getSearchFilters(benchmarkReport, "userId");

                displaysArray = await Promise.all(displaysArray.map(async (item: SearchFilterItem) => {
                    const account = await getDocumentById("accounts", item.value);

                    let newLabel = item.label;

                    if (account.status === 200 && account.data) {
                        newLabel = account.data.name;
                    }

                    return {
                        ...item,
                        label: newLabel
                    }
                }));

                const sucursalsArray = getSearchFilters(benchmarkReport, "nameSucursal");

                setSearchFilters({
                    userId: {
                        allChecked: true,
                        allHidden: false,
                        items: displaysArray,
                    },
                    nameSucursal: {
                        allChecked: true,
                        allHidden: false,
                        items: sucursalsArray,
                    },
                });

                const benchmarkFilteredReport = benchmarkReport.slice(0, 10);

                let reportAux: SucursalRecord[][] = [];

                const sucursalsRecordsArray: SucursalRecord[] = await Promise.all(
                    benchmarkFilteredReport.map(async (reportRow) => {
                        const gondolaColdRecordsArray: GondolaColdRecord[] = [];

                        const displaySnapshot = await getDoc(doc(FirebaseDB, "accounts", reportRow.userId));
                        let displayName: string = "";

                        if (displaySnapshot.exists()) {
                            displayName = displaySnapshot.data().name ?? "";
                        }

                        const created: string = convertTimestampToDate(reportRow.created_at) ?? "";
                        const createdComplete: string = formatTimestampToLocaleDate(reportRow.created_at) ?? "";

                        let finished = reportRow.statusDetails?.find((status: any) => status.type === "gondolaandcold" && status.finished === true);
                        let dateFinished: any = null;

                        if (finished) {
                            dateFinished = formatTimestampToLocaleDate(finished.saveAt);
                        }

                        if (!reportRow.hasOwnProperty("images")) {
                            gondolaColdRecordsArray.push(
                                {
                                    documentKey: reportRow.$key,
                                    id: reportRow.sucursal,
                                    name: displayName,
                                    sucursal: reportRow.nameSucursal,
                                    created,
                                    createdComplete,
                                    finishedAt: dateFinished ?? "",
                                    section: "",
                                    subsection: "",
                                    images: [],
                                }
                            )
                        } else {
                            for (let i = 0; i < reportRow.images.length; i++) {
                                const imagesArray: any[] = [];

                                if (reportRow.images[i].images?.length > 0) {
                                    for (let j = 0; j < reportRow.images[i].images?.length; j++) {
                                        imagesArray.push(reportRow.images[i].images[j].url);
                                    }
                                }

                                gondolaColdRecordsArray.push(
                                    {
                                        documentKey: reportRow.$key,
                                        id: reportRow.sucursal,
                                        name: displayName,
                                        sucursal: reportRow.nameSucursal,
                                        created,
                                        createdComplete,
                                        finishedAt: dateFinished ?? "",
                                        section: reportRow.images[i].section,
                                        subsection: reportRow.images[i].subsection,
                                        images: imagesArray,
                                    }
                                )
                            }
                        }

                        const sucursalRecord: SucursalRecord = {
                            key: reportRow.$key,
                            display: displayName,
                            created,
                            createdComplete,
                            finishedAt: dateFinished ?? "",
                            sucursal: reportRow.nameSucursal,
                            records: gondolaColdRecordsArray
                        }

                        return sucursalRecord;
                    })
                );

                reportAux.push(sucursalsRecordsArray);

                setReport(reportAux);
                setVisibleReport(reportAux[currentPage - 1]);
            } else {
                setReport([]);
                setVisibleReport([]);
            }
        } catch (error: any) {
            setReport([]);
            setVisibleReport([]);
        } finally {
            setSearchingBenchmark(false);
        }
    }

    useEffect(() => {
        getBenchmarkDocs();
    }, [hasDatesChanged]);

    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        let visibleReportAux: SucursalRecord[] = [];

        if (report[value - 1] === undefined) {
            const benchmarkFilteredReport = filteredBenchmarks.slice(recordsPerView * (value - 1), recordsPerView * value);

            visibleReportAux = await Promise.all(
                benchmarkFilteredReport.map(async (reportRow) => {
                    const gondolaColdRecordsArray: GondolaColdRecord[] = [];

                    const displaySnapshot = await getDoc(doc(FirebaseDB, "accounts", reportRow.userId));
                    let displayName: string = "";

                    if (displaySnapshot.exists()) {
                        displayName = displaySnapshot.data().name ?? "";
                    }

                    const created: string = convertTimestampToDate(reportRow.created_at) ?? "";
                    const createdComplete: string = formatTimestampToLocaleDate(reportRow.created_at) ?? "";

                    let finished = reportRow.statusDetails?.find((status: any) => status.type === "gondolaandcold" && status.finished === true);
                    let dateFinished: any = null;

                    if (finished) {
                        dateFinished = formatTimestampToLocaleDate(finished.saveAt);
                    }

                    if (!reportRow.hasOwnProperty("images")) {
                        gondolaColdRecordsArray.push(
                            {
                                documentKey: reportRow.$key,
                                id: reportRow.sucursal,
                                name: displayName,
                                sucursal: reportRow.nameSucursal,
                                created,
                                createdComplete,
                                finishedAt: dateFinished ?? "",
                                section: "",
                                subsection: "",
                                images: [],
                            }
                        )
                    } else {
                        for (let i = 0; i < reportRow.images.length; i++) {
                            const imagesArray: any[] = [];

                            if (reportRow.images[i].images?.length > 0) {
                                for (let j = 0; j < reportRow.images[i].images?.length; j++) {
                                    imagesArray.push(reportRow.images[i].images[j].url);
                                }
                            }

                            gondolaColdRecordsArray.push(
                                {
                                    documentKey: reportRow.$key,
                                    id: reportRow.sucursal,
                                    name: displayName,
                                    sucursal: reportRow.nameSucursal,
                                    created,
                                    createdComplete,
                                    finishedAt: dateFinished ?? "",
                                    section: reportRow.images[i].section,
                                    subsection: reportRow.images[i].subsection,
                                    images: imagesArray,
                                }
                            )
                        }
                    }

                    const sucursalRecord: SucursalRecord = {
                        key: reportRow.$key,
                        display: displayName,
                        created,
                        createdComplete,
                        finishedAt: dateFinished ?? "",
                        sucursal: reportRow.nameSucursal,
                        records: gondolaColdRecordsArray
                    }

                    return sucursalRecord;
                })
            );

            let reportAux = report;

            reportAux[value - 1] = visibleReportAux;

            setReport(reportAux);
        } else {
            visibleReportAux = report[value - 1];
        }

        setVisibleReport(visibleReportAux);
        setCurrentPage(value);

        if (contentRef && contentRef.current) {
            contentRef.current.scrollToTop(500);
        }

        setFilteringReport(false);
    }

    const showImageHandler = (images: string[]) => {
        setImagesToShow(images);
        setShowImageModal(true);
    }

    const changeStartDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newStartDate = formatISODateStringToDate(stringDate, false);

            if (newStartDate) {
                const diferenciaMilisegundos = Number(finishDateSearchFilter) - Number(newStartDate);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 30) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newStartDate);

                    const newFinishDate = new Date(dateAux.setDate(dateAux.getDate() + 30));

                    if (Number(newFinishDate) > Number(today)) {
                        setFinishDateSearchFilter(today);
                    } else {
                        setFinishDateSearchFilter(newFinishDate)
                    }
                }

                setStartDateSearchFilter(newStartDate);
            }
        }
    }

    const changeFinishDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newFinishDate = formatISODateStringToDate(stringDate, true);

            if (newFinishDate) {
                const diferenciaMilisegundos = Number(newFinishDate) - Number(startDateSearchFilter);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 30) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newFinishDate);

                    const newStartDate = new Date(dateAux.setDate(dateAux.getDate() - 30));

                    setStartDateSearchFilter(newStartDate);
                }

                setFinishDateSearchFilter(newFinishDate);
            }
        }
    }

    const applyDateFilter = () => {
        if ((startDate !== startDateSearchFilter) || (finishDate !== finishDateSearchFilter)) {
            setStartDate(startDateSearchFilter);
            setFinishDate(finishDateSearchFilter);
            setCurrentPage(1);
            setHasDatesChanged(hasDatesChanged + 1);
        }
    }

    const allCheckedChangeHandler = (filterName: GondolaColdFilters) => {
        const filterCategory = searchFilters[filterName];
        const newCheckedState = !filterCategory.allChecked;

        const updatedItems = filterCategory.items.map(item => {
            if (item.visible) {
                return { ...item, checked: newCheckedState };
            }
            return item;
        });

        const searchFiltersAux = {
            ...searchFilters,
            [filterName]: {
                ...searchFilters[filterName],
                allChecked: newCheckedState,
                items: updatedItems
            }
        };

        updateFiltersAndBenchmarks(searchFiltersAux, filterName);
    };

    const changeSearchFilterHandler = (event: React.ChangeEvent<HTMLInputElement>, filterName: GondolaColdFilters) => {
        const filterCategory = searchFilters[filterName];
        const filterArray = filterCategory.items;

        const indexFilter = filterArray.findIndex(item => item.value === event.target.value);

        if (indexFilter !== -1) {
            const updatedFilter = { ...filterArray[indexFilter], checked: event.target.checked };

            const updatedFilterArray = [
                ...filterArray.slice(0, indexFilter),
                updatedFilter,
                ...filterArray.slice(indexFilter + 1)
            ];

            const searchFiltersAux = {
                ...searchFilters,
                [filterName]: {
                    ...searchFilters[filterName],
                    items: updatedFilterArray
                }
            };

            updateFiltersAndBenchmarks(searchFiltersAux, filterName);
        }
    };

    const updateFiltersAndBenchmarks = (searchFiltersAux: Record<GondolaColdFilters, SearchFilters>, filterName: GondolaColdFilters) => {
        const filterCategory = searchFiltersAux[filterName];
        const checkedFilters = filterCategory.items.filter(item => item.checked).map(item => item.value);
        let newFilteredBenchmark = benchmarks.filter(item => checkedFilters.includes(item[filterName]));

        for (const key in searchFiltersAux) {
            if (key !== filterName) {
                const foundSearchFilters = getSearchFiltersValues(newFilteredBenchmark, key as GondolaColdFilters);

                searchFiltersAux[key as GondolaColdFilters].items = searchFiltersAux[key as GondolaColdFilters].items.map(item => ({
                    ...item,
                    visible: foundSearchFilters.includes(item.value)
                }));
            }

            const allChecked = searchFiltersAux[key as GondolaColdFilters].items.every(item => !item.visible || item.checked);
            const allHidden = searchFiltersAux[key as GondolaColdFilters].items.every(item => !item.visible);

            searchFiltersAux[key as GondolaColdFilters] = {
                ...searchFiltersAux[key as GondolaColdFilters],
                allChecked,
                allHidden,
            }
        }

        const activeFilters = Object.keys(searchFiltersAux).reduce((acc, key) => {
            const filters = searchFiltersAux[key as GondolaColdFilters].items
                .filter(item => item.checked && item.visible)
                .map(item => item.value);
            return { ...acc, [key]: filters };
        }, {} as Record<GondolaColdFilters, string[]>);

        newFilteredBenchmark = benchmarks.filter(item => {
            return Object.keys(activeFilters).every(key => {
                const filterKey = key as GondolaColdFilters;
                return activeFilters[filterKey].includes(item[filterKey]);
            });
        });

        setSearchFilters(searchFiltersAux);
        setFilteredBenchmarks(newFilteredBenchmark);
        setCurrentPage(1);
        setPaginationCount(Math.ceil(newFilteredBenchmark.length / recordsPerView));
    };

    const changeFilteredBenchmarkHandler = async () => {
        const benchmarkFilteredReport = filteredBenchmarks.slice(0, 10);

        let reportAux: SucursalRecord[][] = [];

        const sucursalsRecordsArray: SucursalRecord[] = await Promise.all(
            benchmarkFilteredReport.map(async (reportRow) => {
                const gondolaColdRecordsArray: GondolaColdRecord[] = [];

                const displaySnapshot = await getDoc(doc(FirebaseDB, "accounts", reportRow.userId));
                let displayName: string = "";

                if (displaySnapshot.exists()) {
                    displayName = displaySnapshot.data().name ?? "";
                }

                const created: string = convertTimestampToDate(reportRow.created_at) ?? "";
                const createdComplete: string = formatTimestampToLocaleDate(reportRow.created_at) ?? "";

                let finished = reportRow.statusDetails?.find((status: any) => status.type === "gondolaandcold" && status.finished === true);
                let dateFinished: any = null;

                if (finished) {
                    dateFinished = formatTimestampToLocaleDate(finished.saveAt);
                }

                if (!reportRow.hasOwnProperty("images")) {
                    gondolaColdRecordsArray.push(
                        {
                            documentKey: reportRow.$key,
                            id: reportRow.sucursal,
                            name: displayName,
                            sucursal: reportRow.nameSucursal,
                            created,
                            createdComplete,
                            finishedAt: dateFinished ?? "",
                            section: "",
                            subsection: "",
                            images: [],
                        }
                    )
                } else {
                    for (let i = 0; i < reportRow.images.length; i++) {
                        const imagesArray: any[] = [];

                        if (reportRow.images[i].images?.length > 0) {
                            for (let j = 0; j < reportRow.images[i].images?.length; j++) {
                                imagesArray.push(reportRow.images[i].images[j].url);
                            }
                        }

                        gondolaColdRecordsArray.push(
                            {
                                documentKey: reportRow.$key,
                                id: reportRow.sucursal,
                                name: displayName,
                                sucursal: reportRow.nameSucursal,
                                created,
                                createdComplete,
                                finishedAt: dateFinished ?? "",
                                section: reportRow.images[i].section,
                                subsection: reportRow.images[i].subsection,
                                images: imagesArray,
                            }
                        )
                    }
                }

                const sucursalRecord: SucursalRecord = {
                    key: reportRow.$key,
                    display: displayName,
                    created,
                    createdComplete,
                    finishedAt: dateFinished ?? "",
                    sucursal: reportRow.nameSucursal,
                    records: gondolaColdRecordsArray
                }

                return sucursalRecord;
            })
        );

        reportAux.push(sucursalsRecordsArray);

        setReport(reportAux);
        setVisibleReport(reportAux[currentPage - 1]);
        setFilteringReport(false);
    }

    useEffect(() => {
        changeFilteredBenchmarkHandler();
    }, [filteredBenchmarks]);

    return (
        <IonPage>
            <IonContent ref={contentRef}>
                <div className="w-full h-screen grid grid-cols-12">
                    {/* Menú lateral */}
                    <div className="col-span-2 border-r-2 border-light">
                        <SidebarMenu pageName="gondola_cold" />
                    </div>

                    <div className="w-full h-screen flex flex-col col-span-10">
                        <div className="w-full flex justify-between border-b-2 border-tertiary px-2 py-3">
                            <p className="text-primary font-bold text-lg">Reporte de Góndola y Frío</p>

                            <p className="font-semibold text-lg">Del {formatDateToLocaleDateString(startDate)} al {formatDateToLocaleDateString(finishDate)}</p>
                        </div>

                        <div id="main-section" className="w-full h-screen overflow-y-scroll grid grid-cols-10">
                            {
                                searchingBenchmark &&
                                <div className="col-span-10 w-full flex flex-col items-center justify-center">
                                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                </div>
                            }

                            {
                                !searchingBenchmark &&
                                <div className="w-full flex flex-col flex-grow col-span-7">
                                    {
                                        filteringReport &&
                                        <div className="w-full h-screen flex flex-col items-center justify-center">
                                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                        </div>
                                    }

                                    {
                                        !filteringReport && !(visibleReport.length > 0) &&
                                        <div className="h-full grid grid-cols-8">
                                            <div className="col-span-8 flex flex-col items-center gap-4 p-4">
                                                <img src={EmptyFolder} alt='empty' className="h-[200px] object-contain" />

                                                <p className="text-xl text-center font-semibold text-primary">No se encontraron resultados para tu búsqueda</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !filteringReport && visibleReport.length > 0 &&
                                        <div className="w-full h-full flex flex-col gap-2">
                                            <div className="px-2 pt-4 pb-1 grid grid-cols-8 gap-4 text-mid border-b-2 border-tertiary [&>p]:mt-auto [&>p]:text-primary [&>p]:font-semibold">
                                                <p>Display</p>
                                                <p>Fecha de creación</p>
                                                <p>Fecha de inicio</p>
                                                <p>Fecha de finalización</p>
                                                <p>Sucursal</p>
                                                <p>Sección</p>
                                                <p>Subsección</p>
                                                <p>Imagen</p>
                                            </div>

                                            <div className="w-full flex flex-col px-2">
                                                {
                                                    visibleReport.map((item: SucursalRecord) => (
                                                        <Accordion key={item.key} defaultExpanded={false}>
                                                            <AccordionSummary
                                                                expandIcon={<IonIcon icon={caretDown} className="text-[25px] text-black" />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                                sx={{ backgroundColor: '#F4F5F5' }}
                                                            >
                                                                <div
                                                                    className="w-full items-center grid grid-cols-8 gap-4 text-mid"
                                                                >
                                                                    <p>{item.display}</p>
                                                                    <p>{item.created}</p>
                                                                    <p>{item.createdComplete}</p>
                                                                    <p>{item.finishedAt}</p>
                                                                    <p>{item.sucursal}</p>
                                                                </div>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                {
                                                                    item.records.map((record: GondolaColdRecord, index: number) => (
                                                                        <div key={item.key + index} className='w-full bg-white cursor:pointer [&>div]:text-ellipsis [&>div]:overflow-hidden [&>div]:... [&>div]:p-2 text-sm text-gray-700 grid grid-cols-8'>
                                                                            <div> {record.name} </div>
                                                                            <div> {record.created} </div>
                                                                            <div> {record.createdComplete} </div>
                                                                            <div> {record.finishedAt} </div>
                                                                            <div> {record.sucursal} </div>
                                                                            <div> {record.section} </div>
                                                                            <div> {record.subsection} </div>
                                                                            {
                                                                                record.images.length > 0 &&
                                                                                <div>
                                                                                    <button
                                                                                        className='bg-tertiary hover:opacity-80 font-semibold px-2 py-1 rounded-lg text-small'
                                                                                        onClick={() => showImageHandler(record.images)}>{record.images.length > 1 ? "Ver imágenes" : "Ver imagen"}
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className={`w-full flex justify-center mt-auto py-4`}>
                                        <Pagination
                                            size="large"
                                            color="primary"
                                            count={paginationCount}
                                            defaultPage={1}
                                            page={currentPage}
                                            siblingCount={1}
                                            boundaryCount={2}
                                            onChange={changePaginationHandler}
                                        />
                                    </div>
                                </div>
                            }

                            {/* Filtros de búsqueda */}
                            {
                                !searchingBenchmark &&
                                <div className="w-full overflow-y-scroll flex p-2 flex-col col-span-3 order-last border-l-2 border-light">
                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Fecha</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col items-center gap-12">
                                                <div className="flex flex-col gap-2">
                                                    <p className="font-semibold">Fecha de inicio</p>
                                                    <IonDatetime
                                                        presentation="date"
                                                        value={formatDateToISODateString(startDateSearchFilter)}
                                                        max={formatDateToISODateString(today)}
                                                        onIonChange={(event) => changeStartDateHandler(event.detail.value)}
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-2">
                                                    <p className="font-semibold">Fecha de fin</p>
                                                    <IonDatetime
                                                        presentation="date"
                                                        value={formatDateToISODateString(finishDateSearchFilter)}
                                                        max={formatDateToISODateString(today)}
                                                        onIonChange={(event) => changeFinishDateHandler(event.detail.value)}
                                                    />
                                                </div>

                                                <div className="w-full xl:w-1/2">
                                                    <SolidButton
                                                        text="Aplicar"
                                                        backgroundColor="primary"
                                                        disabled={false}
                                                        onClickHandler={applyDateFilter}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Displays</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col">
                                                {
                                                    searchFilters.userId.allHidden &&
                                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                                }

                                                {
                                                    !searchFilters.userId.allHidden &&
                                                    <FormControlLabel
                                                        label={"Todos"}
                                                        control=
                                                        {
                                                            <Checkbox
                                                                id={"displays_all"}
                                                                name={"displays_all"}
                                                                checked={searchFilters.userId.allChecked}
                                                                onChange={() => allCheckedChangeHandler("userId")}
                                                            />
                                                        }
                                                    />
                                                }

                                                {
                                                    !searchFilters.userId.allHidden && searchFilters.userId.items.map((item: SearchFilterItem) => {
                                                        if (item.visible) {
                                                            return (
                                                                <FormControlLabel
                                                                    key={item.value}
                                                                    label={item.label}
                                                                    control=
                                                                    {
                                                                        <Checkbox
                                                                            id={item.value}
                                                                            name={item.value}
                                                                            value={item.value}
                                                                            checked={item.checked}
                                                                            onChange={(event) => changeSearchFilterHandler(event, "userId")}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Sucursales</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col">
                                                {
                                                    searchFilters.nameSucursal.allHidden &&
                                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                                }

                                                {
                                                    !searchFilters.nameSucursal.allHidden &&
                                                    <FormControlLabel
                                                        label={"Todas"}
                                                        control=
                                                        {
                                                            <Checkbox
                                                                id={"sucursals_all"}
                                                                name={"sucursals_all"}
                                                                checked={searchFilters.nameSucursal.allChecked}
                                                                onChange={() => allCheckedChangeHandler("nameSucursal")}
                                                            />
                                                        }
                                                    />
                                                }

                                                {
                                                    !searchFilters.nameSucursal.allHidden && searchFilters.nameSucursal.items.map((item: SearchFilterItem) => {
                                                        if (item.visible) {
                                                            return (
                                                                <FormControlLabel
                                                                    key={item.value}
                                                                    label={item.value}
                                                                    control=
                                                                    {
                                                                        <Checkbox
                                                                            id={item.value}
                                                                            name={item.value}
                                                                            value={item.value}
                                                                            checked={item.checked}
                                                                            onChange={(event) => changeSearchFilterHandler(event, "nameSucursal")}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    }

                                                        // <IonCheckbox key={item.value} mode="md" checked={item.checked} labelPlacement="end">
                                                        //     {item.value}
                                                        // </IonCheckbox>
                                                    )
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </IonContent>

            {
                showImageModal &&
                <ModalBackground />
            }

            {
                showImageModal &&
                <ImageVisualizer imageArray={imagesToShow} imageAlt="prueba" clickOutsideHandler={() => setShowImageModal(false)} />
            }
        </IonPage>
    )
}