import { useOnClickOutside } from 'hooks';
import { useRef, useState } from 'react';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/zoom';

import { Zoom } from 'swiper/modules';
import { IonIcon } from '@ionic/react';
import { add, remove, chevronBack, chevronForward } from 'ionicons/icons';

export const ImageVisualizer = (
    {
        imageArray,
        imageAlt,
        clickOutsideHandler
    }:
        {

            imageArray: string[],
            imageAlt: string,
            clickOutsideHandler: () => void
        }
) => {
    const [imageToShow, setImageToShow] = useState<string>(imageArray[0]);
    const [indexCurrentImage, setIndexCurrentImage] = useState<number>(0);

    const modalRef = useRef(null);
    const swiperRef = useRef<SwiperRef>(null);

    useOnClickOutside(modalRef, clickOutsideHandler);

    const changeImageHandler = (newIndex: number) => {
        setImageToShow(imageArray[newIndex]);
        setIndexCurrentImage(newIndex);
        swiperRef.current?.swiper.zoom.out();
    }

    return (
        <div className='w-screen h-screen fixed flex justify-center items-center px-10 z-40'>
            <Swiper
                zoom={true}
                modules={[Zoom]}
                className="mySwiper w-full h-[85%]"
                ref={swiperRef}
            >
                <SwiperSlide>
                    <div ref={modalRef} className="w-full h-full flex flex-col bg-white">
                        <div className="swiper-zoom-container h-full ">
                            <img src={imageToShow} alt={imageAlt} className='object-contain' />
                        </div>

                        <div className="w-full flex justify-center absolute bottom-1 left-0 right-0">
                            <div className=" flex justify-center items-center bg-black gap-4 px-[2px] py-[2px]">
                                <button onClick={() => swiperRef.current?.swiper.zoom.out()} className="px-4 py-2 text-white hover:bg-medium flex items-center justify-center">
                                    <IonIcon icon={remove} className="text-white text-[20px]" />
                                </button>
                                <button onClick={() => swiperRef.current?.swiper.zoom.in()} className="px-4 py-2 text-white hover:bg-medium flex items-center justify-center">
                                    <IonIcon icon={add} className="text-white text-[20px]" />
                                </button>
                            </div>
                        </div>

                        {
                            imageArray.length > 1 &&
                            <div className="w-full flex justify-center">
                                <div className="absolute top-0 bottom-0 left-1 pl-4 flex items-center">
                                    <button
                                        onClick={() => changeImageHandler(indexCurrentImage - 1)} 
                                        disabled={indexCurrentImage === 0} 
                                        className={`flex justify-center items-center bg-black px-4 py-2 ${indexCurrentImage === 0 && 'opacity-50'}`}
                                    >
                                        <IonIcon icon={chevronBack} className="text-white text-[30px]" />
                                    </button>
                                </div>

                                <div className="absolute top-0 bottom-0 right-1 pr-4 flex items-center">
                                    <button
                                        onClick={() => changeImageHandler(indexCurrentImage + 1)}  
                                        disabled={indexCurrentImage === imageArray.length - 1} 
                                        className={`flex justify-center items-center bg-black px-4 py-2 ${indexCurrentImage === imageArray.length - 1 && 'opacity-50'}`}
                                    >
                                        <IonIcon icon={chevronForward} className="text-white text-[30px]" />
                                    </button>
                                </div>

                            </div>
                        }
                    </div>

                </SwiperSlide>
            </Swiper>
        </div>
    )
}