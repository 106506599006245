import Logo from 'assets/img/logos/premier-tools-logo.png';
import { IonContent, IonPage, useIonRouter } from "@ionic/react";
import { PasswordField, SolidButton, TextField } from "components";
import { FirebaseAuth } from "firebase-config"
import { Persistence, browserLocalPersistence, setPersistence, signInWithEmailAndPassword } from "firebase/auth"
import { useAppDispatch, useForm } from "hooks";
import { checkingCredentials, logout } from "store/auth";
import { getEmailErrorMessage, getPasswordErrorMessage, validateEmail, validatePassword } from "utils";
import { validateAuth } from 'services';

const formData = {
    email: '',
    password: '',
}

const formValidations = {
    email: [validateEmail, getEmailErrorMessage],
    password: [validatePassword, getPasswordErrorMessage]
}

export const SignInPage = () => {
    const { email, password, emailValid, passwordValid, isFormValid, onInputChange } = useForm(formData, formValidations);

    const dispatch = useAppDispatch();
    const ionRouter = useIonRouter();

    const signInHandler = async () => {
        dispatch(checkingCredentials());

        await signInWithEmailAndPassword(FirebaseAuth, email, password)
            .then(() => {
                ionRouter.push("/menu", "root", "replace");
            })
            .catch(() => {
                dispatch(logout());
            })
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-full flex justify-center">
                    <div className="container flex flex-col justify-center items-center">
                        <div className="w-full flex flex-col justify-center items-center p-6 pt-16 relative">
                            <div className="absolute top-0 bg-white px-6 py-4 rounded-lg logo-card">
                                <img src={Logo} alt='PremierTools' className="w-[100px]" />
                            </div>

                            <div className="w-1/3 flex flex-col items-center gap-6 pt-24 pb-10 rounded-md logo-card">
                                <div className="w-full flex flex-col items-center gap-4">
                                    <div className="w-3/5">
                                        <p className="font-bold text-large">INICIAR SESIÓN</p>
                                    </div>


                                    <div className="w-3/5">
                                        <TextField
                                            name='email'
                                            label='Correo Electrónico'
                                            value={email}
                                            inputMode="email"
                                            changeHandler={onInputChange}
                                            autoCapitalize="none"
                                            isFieldValid={emailValid}
                                            errorMessage={emailValid}
                                        />
                                    </div>

                                    <div className="w-3/5">
                                        <PasswordField
                                            name='password'
                                            label='Contraseña'
                                            value={password}
                                            changeHandler={onInputChange}
                                            isFieldValid={passwordValid}
                                            errorMessage={passwordValid}
                                        />
                                    </div>
                                </div>

                                <div className="w-full flex flex-col items-center">
                                    <div className="w-3/5">
                                        <SolidButton
                                            text='Iniciar sesión'
                                            backgroundColor='primary'
                                            disabled={!isFormValid}
                                            onClickHandler={signInHandler}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}