import EmptyFolder from 'assets/img/app/empty-folder.png';
import { IonDatetime, IonIcon, IonSpinner } from "@ionic/react"
import { SolidButton } from "components";
import React, { SetStateAction, useEffect, useState } from "react";
import { calculateSellerFulfillmentPercentage, formatDateToISODateString, formatISODateStringToDate, getSearchFilters, getSearchFiltersValues } from "utils";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Pagination } from '@mui/material';
import { caretDown } from 'ionicons/icons';
import { SearchFilterItem, SearchFilters, SellerPerformanceRecord } from 'interfaces';
import { IconSeller } from 'assets';
import { getSellersControlService } from 'services';
import { useAppSelector } from 'hooks';

// Función para determinar el color de la barra
const getBarColor = (percentage: number) => {
    if (percentage <= 30) return 'bg-progress-bar-red';
    if (percentage <= 50) return 'bg-progress-bar-orange';
    if (percentage <= 80) return 'bg-progress-bar-yellow';
    return 'bg-progress-bar-green';
};

type CustomerVisitControlFilters = "vendorName";

const initialSearchFilters: Record<CustomerVisitControlFilters, SearchFilters> = {
    vendorName: {
        allChecked: true,
        allHidden: false,
        items: [],
    },
}

export const CustomersVisitControl = (
    {
        showOrdersDetails,
        contentRef,
        today,
        startDate,
        setStartDate,
        startDateSearchFilter,
        setStartDateSearchFilter,
        finishDate,
        setFinishDate,
        finishDateSearchFilter,
        setFinishDateSearchFilter,
        hasDatesChanged,
        setHasDatesChanged
    }:
        {
            showOrdersDetails: boolean,
            contentRef: React.RefObject<HTMLIonContentElement>,
            today: Date,
            startDate: Date,
            setStartDate: React.Dispatch<SetStateAction<Date>>,
            startDateSearchFilter: Date,
            setStartDateSearchFilter: React.Dispatch<SetStateAction<Date>>,
            finishDate: Date,
            setFinishDate: React.Dispatch<SetStateAction<Date>>,
            finishDateSearchFilter: Date,
            setFinishDateSearchFilter: React.Dispatch<SetStateAction<Date>>,
            hasDatesChanged: number,
            setHasDatesChanged: React.Dispatch<SetStateAction<number>>
        }
) => {
    const { uid } = useAppSelector(state => state.user);

    // estado para almacenar los benchmarks recuperados
    const [sellersData, setSellersData] = useState<any[]>([]);
    const [filteredSellersData, setFilteredSellersData] = useState<any[]>([]);

    // estados para almacenar los datos del reporte
    const [report, setReport] = useState<SellerPerformanceRecord[][]>([]);
    const [visibleReport, setVisibleReport] = useState<SellerPerformanceRecord[]>([]);

    // estados para manejar la paginación
    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(10);

    // estados para manejar las pantallas de carga
    const [searchingSellersData, setSearchingSellersData] = useState<boolean>(false);
    const [filteringReport, setFilteringReport] = useState<boolean>(false);

    // estados para almacenar los valores que aparecerán en los filtros de búsqueda
    const [searchFilters, setSearchFilters] = useState<Record<CustomerVisitControlFilters, SearchFilters>>(initialSearchFilters);

    const getSellersData = async () => {
        try {
            setSearchingSellersData(true);

            if (uid) {
                let sellersReport = await getSellersControlService(uid, formatDateToISODateString(startDate), formatDateToISODateString(finishDate));

                sellersReport = sellersReport.filter((item: any, indice: number, self: any) =>
                    indice === self.findIndex((t: any) => (
                        t.vendorName === item.vendorName
                    )));

                setPaginationCount(Math.ceil(sellersReport.length / recordsPerView));
                setSellersData(sellersReport);
                setFilteredSellersData(sellersReport);

                // Obtenemos los filtros de búsqueda
                const sellersArray = getSearchFilters(sellersReport, "vendorName");

                setSearchFilters({
                    vendorName: {
                        allChecked: true,
                        allHidden: false,
                        items: sellersArray,
                    },
                });

                const filteredSellers = sellersReport.slice(0, 10);

                let reportAux: SellerPerformanceRecord[][] = [];

                const sellersPerformanceArray: SellerPerformanceRecord[] = filteredSellers.map((seller: any) => {
                    return {
                        seller: seller.vendorName,
                        code: seller.salesPersonCode,
                        assignedCustomers: seller.allsClients,
                        visitedCustomers: seller.visitClient,
                        percentage: calculateSellerFulfillmentPercentage(seller.allsClients, seller.visitClient)
                    }
                });

                reportAux.push(sellersPerformanceArray);

                setReport(reportAux);
                setVisibleReport(reportAux[currentPage - 1]);
            } else {
                throw new Error();
            }
        } catch (error: any) {
            setReport([]);
            setVisibleReport([]);
        } finally {
            setSearchingSellersData(false);
        }
    }

    useEffect(() => {
        getSellersData()
    }, [hasDatesChanged]);

    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        let visibleReportAux: SellerPerformanceRecord[] = [];

        if (report[value - 1] === undefined) {
            const filteredSellers = filteredSellersData.slice(recordsPerView * (value - 1), recordsPerView * value);

            visibleReportAux = filteredSellers.map((seller: any) => {
                return {
                    seller: seller.vendorName,
                    code: seller.salesPersonCode,
                    assignedCustomers: seller.allsClients,
                    visitedCustomers: seller.visitClient,
                    percentage: calculateSellerFulfillmentPercentage(seller.allsClients, seller.visitClient)
                }
            });

            let reportAux = report;

            reportAux[value - 1] = visibleReportAux;

            setReport(reportAux);
        } else {
            visibleReportAux = report[value - 1];
        }

        setVisibleReport(visibleReportAux);
        setCurrentPage(value);

        if (contentRef && contentRef.current) {
            contentRef.current.scrollToTop(500);
        }

        setFilteringReport(false);
    }

    const changeStartDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newStartDate = formatISODateStringToDate(stringDate, false);

            if (newStartDate) {
                const diferenciaMilisegundos = Number(finishDateSearchFilter) - Number(newStartDate);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 7) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newStartDate);

                    const newFinishDate = new Date(dateAux.setDate(dateAux.getDate() + 7));

                    if (Number(newFinishDate) > Number(today)) {
                        setFinishDateSearchFilter(today);
                    } else {
                        setFinishDateSearchFilter(newFinishDate)
                    }
                }

                setStartDateSearchFilter(newStartDate);
            }
        }
    }

    const changeFinishDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newFinishDate = formatISODateStringToDate(stringDate, true);

            if (newFinishDate) {
                const diferenciaMilisegundos = Number(newFinishDate) - Number(startDateSearchFilter);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 7) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newFinishDate);

                    const newStartDate = new Date(dateAux.setDate(dateAux.getDate() - 7));

                    setStartDateSearchFilter(newStartDate);
                }

                setFinishDateSearchFilter(newFinishDate);
            }
        }
    }

    const applyDateFilter = () => {
        if ((startDate !== startDateSearchFilter) || (finishDate !== finishDateSearchFilter)) {
            setStartDate(startDateSearchFilter);
            setFinishDate(finishDateSearchFilter);
            setHasDatesChanged(hasDatesChanged + 1);
        }
    }

    const allCheckedChangeHandler = (filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFilters[filterName];
        const newCheckedState = !filterCategory.allChecked;

        const updatedItems = filterCategory.items.map(item => {
            if (item.visible) {
                return { ...item, checked: newCheckedState };
            }
            return item;
        });

        const searchFiltersAux = {
            ...searchFilters,
            [filterName]: {
                ...searchFilters[filterName],
                allChecked: newCheckedState,
                items: updatedItems
            }
        };

        updateFiltersAndBenchmarks(searchFiltersAux, filterName);
    };

    const changeSearchFilterHandler = (event: React.ChangeEvent<HTMLInputElement>, filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFilters[filterName];
        const filterArray = filterCategory.items;

        const indexFilter = filterArray.findIndex(item => item.value === event.target.value);

        if (indexFilter !== -1) {
            const updatedFilter = { ...filterArray[indexFilter], checked: event.target.checked };

            const updatedFilterArray = [
                ...filterArray.slice(0, indexFilter),
                updatedFilter,
                ...filterArray.slice(indexFilter + 1)
            ];

            const searchFiltersAux = {
                ...searchFilters,
                [filterName]: {
                    ...searchFilters[filterName],
                    items: updatedFilterArray
                }
            };

            updateFiltersAndBenchmarks(searchFiltersAux, filterName);
        }
    };

    const updateFiltersAndBenchmarks = (searchFiltersAux: Record<CustomerVisitControlFilters, SearchFilters>, filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFiltersAux[filterName];
        const checkedFilters = filterCategory.items.filter(item => item.checked).map(item => item.value);
        let newFilteredSellersData = sellersData.filter(item => checkedFilters.includes(item[filterName]));

        for (const key in searchFiltersAux) {
            if (key !== filterName) {
                const foundSearchFilters = getSearchFiltersValues(newFilteredSellersData, key as CustomerVisitControlFilters);

                searchFiltersAux[key as CustomerVisitControlFilters].items = searchFiltersAux[key as CustomerVisitControlFilters].items.map(item => ({
                    ...item,
                    visible: foundSearchFilters.includes(item.value)
                }));
            }

            const allChecked = searchFiltersAux[key as CustomerVisitControlFilters].items.every(item => !item.visible || item.checked);
            const allHidden = searchFiltersAux[key as CustomerVisitControlFilters].items.every(item => !item.visible);

            searchFiltersAux[key as CustomerVisitControlFilters] = {
                ...searchFiltersAux[key as CustomerVisitControlFilters],
                allChecked,
                allHidden,
            }
        }

        const activeFilters = Object.keys(searchFiltersAux).reduce((acc, key) => {
            const filters = searchFiltersAux[key as CustomerVisitControlFilters].items
                .filter(item => item.checked && item.visible)
                .map(item => item.value);
            return { ...acc, [key]: filters };
        }, {} as Record<CustomerVisitControlFilters, string[]>);

        newFilteredSellersData = sellersData.filter(item => {
            return Object.keys(activeFilters).every(key => {
                const filterKey = key as CustomerVisitControlFilters;
                return activeFilters[filterKey].includes(item[filterKey]);
            });
        });

        setSearchFilters(searchFiltersAux);
        setFilteredSellersData(newFilteredSellersData);
        setCurrentPage(1);
        setPaginationCount(Math.ceil(newFilteredSellersData.length / recordsPerView));
    };

    const changeFilteredSellersDataHandler = async () => {
        const filteredSellersReport = filteredSellersData.slice(0, 10);

        let reportAux: SellerPerformanceRecord[][] = [];

        const sellersRecordsArray: SellerPerformanceRecord[] = filteredSellersReport.map((seller: any) => {
            return {
                seller: seller.vendorName,
                code: seller.salesPersonCode,
                assignedCustomers: seller.allsClients,
                visitedCustomers: seller.visitClient,
                percentage: calculateSellerFulfillmentPercentage(seller.allsClients, seller.visitClient)
            }
        })

        reportAux.push(sellersRecordsArray);

        setReport(reportAux);
        setVisibleReport(reportAux[currentPage - 1]);
        setFilteringReport(false);
    }

    useEffect(() => {
        changeFilteredSellersDataHandler();
    }, [filteredSellersData]);

    return (
        <div id="main-section" className={`w-full h-screen overflow-y-scroll grid grid-cols-10 ${showOrdersDetails && 'hidden'}`}>
            {
                searchingSellersData &&
                <div className="col-span-10 w-full h-full flex flex-col items-center justify-center">
                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                </div>
            }

            {
                !searchingSellersData &&
                <div className="w-full flex flex-col flex-grow col-span-7">
                    {
                        filteringReport &&
                        <div className="w-full h-screen flex flex-col items-center justify-center">
                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                        </div>
                    }

                    {
                        !filteringReport && !(visibleReport.length > 0) &&
                        <div className="h-screen grid grid-cols-8">
                            <div className="col-span-8 flex flex-col justify-center items-center gap-4 p-4">
                                <img src={EmptyFolder} alt='empty' className="h-[200px] object-contain" />

                                <p className="text-xl text-center font-semibold text-primary">No se encontraron resultados para tu búsqueda</p>
                            </div>
                        </div>
                    }

                    {
                        !filteringReport && visibleReport.length > 0 &&
                        <div className="w-full h-full flex flex-col">
                            <div className="px-2 pt-4 pb-1 grid grid-cols-5  gap-4 text-mid border-b-2 border-tertiary [&>p]:mt-auto [&>p]:text-primary [&>p]:font-semibold">
                                <p>Vendedor</p>
                                <p>Código de Vendedor</p>
                                <p>Clientes Asignados</p>
                                <p>Clientes Visitados</p>
                                <p>Porcentaje de Efectividad</p>
                            </div>

                            <div className="w-full flex flex-col">
                                {
                                    visibleReport.map((item: SellerPerformanceRecord) => (
                                        <div key={item.code} className='w-full px-2 py-1 bg-white odd:bg-light cursor:pointer [&>div]:text-ellipsis [&>div]:overflow-hidden [&>div]:... [&>div]:p-2 text-sm text-gray-700 grid grid-cols-5'>
                                            <div className="w-full flex items-center gap-3">
                                                <div className="w-[18px]">
                                                    <IconSeller />
                                                </div>

                                                <p> {item.seller}</p>
                                            </div>
                                            <div> {item.code} </div>
                                            <div> {item.assignedCustomers} </div>
                                            <div> {item.visitedCustomers} </div>
                                            <div className="w-full flex items-center gap-4">
                                                <div className="progress-bar-container w-full h-[6px] bg-progress-bar-gray rounded-md overflow-hidden">
                                                    <div className={`progress-bar h-[6px] ${getBarColor(item.percentage)}`} style={{ width: `${item.percentage}%` }}>
                                                    </div>
                                                </div>

                                                <p>{item.percentage}% </p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }

                    <div className="w-full flex justify-center mt-auto py-4">
                        <Pagination
                            size="large"
                            color="primary"
                            count={paginationCount}
                            defaultPage={1}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={2}
                            onChange={changePaginationHandler}
                        />
                    </div>
                </div>
            }

            {/* Filtros de búsqueda */}
            {
                !searchingSellersData &&
                <div className="w-full overflow-y-scroll flex p-2 flex-col col-span-3 order-last border-l-2 border-light">
                    <Accordion className="accordion-search-filter">
                        <AccordionSummary
                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="font-semibold text-mid">Fecha</p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="flex flex-col items-center gap-12">
                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Fecha de inicio</p>
                                    <IonDatetime
                                        presentation="date"
                                        value={formatDateToISODateString(startDateSearchFilter)}
                                        max={formatDateToISODateString(today)}
                                        onIonChange={(event) => changeStartDateHandler(event.detail.value)}
                                    />
                                </div>

                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Fecha de fin</p>
                                    <IonDatetime
                                        presentation="date"
                                        value={formatDateToISODateString(finishDateSearchFilter)}
                                        max={formatDateToISODateString(today)}
                                        onIonChange={(event) => changeFinishDateHandler(event.detail.value)}
                                    />
                                </div>

                                <div className="w-full xl:w-1/2">
                                    <SolidButton
                                        text="Aplicar"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={applyDateFilter}
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accordion-search-filter">
                        <AccordionSummary
                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="font-semibold text-mid">Vendedores</p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="flex flex-col">
                                {
                                    searchFilters.vendorName.allHidden &&
                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                }

                                {
                                    !searchFilters.vendorName.allHidden &&
                                    <FormControlLabel
                                        label={"Todos"}
                                        control=
                                        {
                                            <Checkbox
                                                id={"displays_all"}
                                                name={"displays_all"}
                                                checked={searchFilters.vendorName.allChecked}
                                                onChange={() => allCheckedChangeHandler("vendorName")}
                                            />
                                        }
                                    />
                                }

                                {
                                    !searchFilters.vendorName.allHidden && searchFilters.vendorName.items.map((item: SearchFilterItem) => {
                                        if (item.visible) {
                                            return (
                                                <FormControlLabel
                                                    key={item.value}
                                                    label={item.label}
                                                    control=
                                                    {
                                                        <Checkbox
                                                            id={item.value}
                                                            name={item.value}
                                                            value={item.value}
                                                            checked={item.checked}
                                                            onChange={(event) => changeSearchFilterHandler(event, "vendorName")}
                                                        />
                                                    }
                                                />
                                            )
                                        }
                                    })
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </div>
    )
}