import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { CircularGraphicElement } from "../interfaces/dynamic-dashboard.interfaces";
import { graphicColorsArray } from "assets";

export const BrandSalesTab = ({ brandSales }: { brandSales: CircularGraphicElement[] }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = echarts.init(chartRef.current, null, { renderer: "svg" });

        configChannelsComparisonGraphic(chart);

        return () => {
            chart.dispose();
        }
    }, []);

    // Función para construir el gráfico de Comparativa entre canales
    const configChannelsComparisonGraphic = (chart: echarts.ECharts) => {
        chart.setOption({
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)' // Muestra el nombre, valor y porcentaje
            },
            legend: {
                top: '5%',
                left: 'center',
                show: false,
            },
            color: graphicColorsArray,
            series: [
                {
                    name: '',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 20,
                            fontWeight: 'semibold'
                        }
                    },
                    labelLine: {
                        show: true
                    },
                    data: brandSales,
                }
            ]
        })
    }

    return (
        <div className="col-span-6 grid grid-cols-2 gap-5 pb-8">
            <div className="flex flex-col items-center bg-white rounded-md logo-card p-4 max-h-max">
                <div ref={chartRef} className={`flex justify-center w-[700px] h-[500px]`} />
            </div>

            <div className="p-4 flex flex-col gap-4 bg-white rounded-md logo-card">
                <div className="grid grid-cols-2 font-bold">
                    <p className="w-full">Marca</p>
                    <p className="w-full text-end">Cantidad (Unidades)</p>
                </div>

                <div className="flex flex-col gap-2">
                    {
                        brandSales.map((item: CircularGraphicElement, index: number) => (
                            <div key={item.name} className="grid grid-cols-2 pb-2 border-b border-tertiary last:border-b-0">
                                <div className="flex items-center gap-3">
                                    <div style={{ backgroundColor: graphicColorsArray[index] }} className="w-[18px] h-[15px] rounded-md"></div>
                                    <p className="w-full uppercase">{item.name}</p>
                                </div>
                                <p className="w-full text-end font-semibold">{item.quantity}</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}