import axios from "axios";

export async function getSellersControlService(uid: string, dayInit: string, dayEnd: string) {
    let serviceResponse: any = null;

    try {
        const res = await axios.post(
            `${process.env.REACT_APP_SERVICE_URL}/documents/get/compliance/range`,
            {
                "token": "MmszRip8dZVcUaN5KZVRX7C4U463",
                "data": {
                    dayInit,
                    dayEnd
                }
            },
            {
                timeout: 15000,
            }
        );
        
        if (res.data.data && res.data.data.length > 0) {
            serviceResponse = res.data.data;
        }
    } catch (error) {
        return null;
    }

    return serviceResponse;
}