export const IconInventory = () => {
    return (
        <svg width="98" height="143" viewBox="0 0 98 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_254_10)">
                <path d="M48.9957 2.71863C52.8933 2.71863 56.057 5.89049 56.057 9.7799H58.5032C58.5032 4.52879 54.2468 0.272461 48.9957 0.272461C43.7446 0.272461 39.4883 4.52879 39.4883 9.7799H41.9344C41.9344 5.88234 45.1063 2.71863 48.9957 2.71863Z" fill="#183555" />
                <path d="M91.6406 14.3542H74.3544V16.7025C74.3544 16.7025 74.3544 16.7678 74.3544 16.8004H91.6406C93.4834 16.8004 94.9837 18.3007 94.9837 20.1435V136.491C94.9837 138.334 93.4834 139.834 91.6406 139.834H6.35909C4.51631 139.834 3.01599 138.334 3.01599 136.491V20.1353C3.01599 18.2926 4.51631 16.7922 6.35909 16.7922H23.6453C23.6453 16.7922 23.6453 16.727 23.6453 16.6944V14.3461H6.35909C3.16276 14.3461 0.569824 16.939 0.569824 20.1353V136.483C0.569824 139.68 3.16276 142.272 6.35909 142.272H91.6406C94.837 142.272 97.4299 139.68 97.4299 136.483V20.1353C97.4299 16.939 94.837 14.3461 91.6406 14.3461V14.3542Z" fill="#183555" />
                <path d="M71.6555 9.77991H26.3443C24.8521 9.77991 23.6372 10.9867 23.6372 12.487V16.7026C23.6372 16.7026 23.6372 16.7678 23.6372 16.8004C23.6861 20.3392 26.5645 23.1849 30.1114 23.1849H67.8639C71.4109 23.1849 74.2892 20.331 74.3381 16.8004C74.3381 16.7678 74.3381 16.7352 74.3381 16.7026V12.487C74.3381 10.9948 73.1313 9.77991 71.631 9.77991H71.6555ZM71.9164 14.3461V16.6944C71.9164 16.6944 71.9082 16.7596 71.9082 16.7923C71.8593 18.9693 70.0736 20.7306 67.8802 20.7306H30.1277C27.9343 20.7306 26.1568 18.9693 26.0997 16.7923C26.0997 16.7596 26.0915 16.727 26.0915 16.6944V12.4788C26.0915 12.3402 26.2057 12.2179 26.3525 12.2179H71.6555C71.7941 12.2179 71.9164 12.3321 71.9164 12.4788V14.3461Z" fill="#183555" />
                <path d="M81.6925 50.4514H34.7832V52.8976H81.6925V50.4514Z" fill="#183555" />
                <path d="M19.9596 53.4032L15.4424 48.8859L17.171 47.1573L19.9596 49.9459L27.6243 42.2812L29.3529 44.0099L19.9596 53.4032Z" fill="#183555" />
                <path d="M81.6925 73.478H34.7832V75.9242H81.6925V73.478Z" fill="#183555" />
                <path d="M19.9596 76.4298L15.4424 71.9044L17.171 70.1757L19.9596 72.9644L27.6243 65.3079L29.3529 67.0365L19.9596 76.4298Z" fill="#183555" />
                <path d="M81.6925 96.4965H34.7832V98.9426H81.6925V96.4965Z" fill="#183555" />
                <path d="M19.9596 99.4482L15.4424 94.9309L17.171 93.2023L19.9596 95.991L27.6243 88.3263L29.3529 90.0549L19.9596 99.4482Z" fill="#183555" />
                <path d="M81.6925 119.523H34.7832V121.969H81.6925V119.523Z" fill="#183555" />
                <path d="M19.9596 122.475L15.4424 117.958L17.171 116.229L19.9596 119.018L27.6243 111.353L29.3529 113.082L19.9596 122.475Z" fill="#183555" />
            </g>
            <defs>
                <clipPath id="clip0_254_10">
                    <rect width="96.8601" height="142" fill="white" transform="translate(0.569824 0.272461)" />
                </clipPath>
            </defs>
        </svg>
    )
}