import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatDateToISODateString } from "utils";

interface SellersDashboardState {
    queryDateSellersDashboard: string,
}

const initialState: SellersDashboardState = {
    queryDateSellersDashboard: formatDateToISODateString(new Date())
}


export const sellersDashboardSlice = createSlice({
    name: 'sellers-dashboard',
    initialState,
    reducers: {
        changeQueryDateSellersDashboard: (state, action: PayloadAction<string>) => {
            state.queryDateSellersDashboard = action.payload;
        },
        resetSellersDashboardState: (state) => {
            state.queryDateSellersDashboard = ""
        }
    },
})

// Action creators are generated for each case reducer function
export const 
{ 
    changeQueryDateSellersDashboard,
    resetSellersDashboardState,
} = sellersDashboardSlice.actions