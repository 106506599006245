import axios from "axios";

export async function getDashboardCampaignService(uid: string) {
    let serviceResponse: any = null;

    const body = {
        token: uid
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/settings/app/info/dashboard`, body);

        if (res.data.data) {
            serviceResponse = res.data.data;
        }
    } catch (error: any) {
        return null;
    }

    return serviceResponse;
}

export async function getGoalsVendorService(uid: string, year: number, month: number, calculateDetails: boolean, order: boolean) {
    let serviceResponse: any = null;

    const body = {
        data: {
            year,
            month,
            calculateDetails,
            order
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `UserToken ${uid}`,
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/sapuser/get/goals/vendor`, body, { headers });

        if (res.data.data) {
            serviceResponse = res.data.data;
        }
    } catch (error: any) {
        return null;
    }

    return serviceResponse;
}

export async function getBrandSalesService(uid: string, year: number, month: number, calculateDetails: boolean, order: boolean) {
    let serviceResponse: any = null;

    const body = {
        data: {
            year,
            month,
            calculateDetails,
            order
        }
    }

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `UserToken ${uid}`,
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVICE_URL}/sapreport/get/sold/brand`, body, { headers });

        if (res.data.data) {
            serviceResponse = res.data.data;
        }
    } catch (error: any) {
        return null;
    }

    return serviceResponse;
}

export async function getCartelsImagesService(uid: string) {
    let serviceResponse: any = null;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `UserToken ${uid}`,
    }

    try {
        const res = await axios.get(`${process.env.REACT_APP_SERVICE_URL}/campaign/get/actually`, { headers });

        if (res.data.data) {
            serviceResponse = res.data.data;
        }
    } catch (error: any) {
        return null;
    }

    return serviceResponse;
}