export const IconIvaCards = () => {
    return (
        <svg width="176" height="113" viewBox="0 0 176 113" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M44.6713 29.5358H37.2408L31.8584 60.02H39.2821L44.6713 29.5358Z" fill="#183555" />
            <path d="M45.3449 59.4348C46.0253 60.054 46.9439 60.367 48.1007 60.367C49.516 60.367 50.7272 59.9043 51.7275 58.9789C52.7278 58.0535 53.2245 56.9376 53.2245 55.6379C53.2245 54.6512 52.8911 53.8483 52.2242 53.2291C51.5574 52.6099 50.6592 52.2969 49.5296 52.2969C48.0531 52.2969 46.8146 52.7596 45.8144 53.685C44.8141 54.6104 44.3174 55.74 44.3174 57.0736C44.3174 58.0263 44.6576 58.8156 45.3381 59.4416L45.3449 59.4348Z" fill="#183555" />
            <path d="M74.7133 60.02L90.9149 29.5358H83.008L71.4131 52.5487L67.902 29.5358H59.9951L65.4251 60.02H74.7133Z" fill="#183555" />
            <path d="M88.2612 57.0668C88.2612 58.0195 88.6014 58.8088 89.2819 59.4348C89.9623 60.054 90.8809 60.367 92.0377 60.367C93.453 60.367 94.6643 59.9043 95.6645 58.9789C96.6648 58.0535 97.1615 56.9376 97.1615 55.6379C97.1615 54.6512 96.8281 53.8483 96.1612 53.2291C95.4944 52.6099 94.5962 52.2969 93.4667 52.2969C91.9901 52.2969 90.7517 52.7596 89.7514 53.685C88.7511 54.6104 88.2544 55.74 88.2544 57.0736L88.2612 57.0668Z" fill="#183555" />
            <path d="M109.41 54.6376H120.787L121.658 60.02H129.517L123.876 29.5358H115.282L98.8696 60.02H106.64L109.417 54.6376H109.41ZM118.14 37.7012L119.923 48.9899H112.322L118.14 37.7012Z" fill="#183555" />
            <path d="M136.288 60.367C137.703 60.367 138.914 59.9043 139.915 58.9789C140.915 58.0535 141.412 56.9376 141.412 55.6379C141.412 54.6512 141.078 53.8483 140.411 53.2291C139.744 52.6099 138.846 52.2969 137.717 52.2969C136.24 52.2969 135.002 52.7596 134.001 53.685C133.001 54.6104 132.504 55.74 132.504 57.0736C132.504 58.0263 132.845 58.8156 133.525 59.4416C134.206 60.0608 135.124 60.3738 136.281 60.3738L136.288 60.367Z" fill="#183555" />
            <path d="M20.3387 66.9199H106.504C107.756 66.9199 108.77 67.9338 108.77 69.1858V81.8558C108.77 83.1079 107.756 84.1217 106.504 84.1217H20.3387C19.0866 84.1217 18.0728 83.1079 18.0728 81.8558V69.1858C18.0728 67.9338 19.0866 66.9199 20.3387 66.9199Z" fill="#183555" />
            <path d="M155.666 66.9199H120.378C119.127 66.9199 118.112 67.9344 118.112 69.1858V81.8558C118.112 83.1073 119.127 84.1217 120.378 84.1217H155.666C156.918 84.1217 157.932 83.1073 157.932 81.8558V69.1858C157.932 67.9344 156.918 66.9199 155.666 66.9199Z" fill="#183555" />
            <path d="M13.86 103.664L162.141 103.664C168.769 103.664 174.141 98.2914 174.141 91.664L174.141 21C174.141 14.3726 168.769 9 162.141 9L13.86 8.99999C7.23259 8.99999 1.86 14.3726 1.86 21L1.86 91.664C1.86 98.2914 7.23259 103.664 13.86 103.664Z" stroke="#183555" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
    )
}