// validateEmail 
export const validateEmail = (email: string) => {
    if (email.length < 1) return false;

    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (!validEmail.test(email)) return false;

    return true;
}

export const getEmailErrorMessage = (email: string) => {
    if (email.length < 1) return 'Campo obligatorio';

    let validEmail = /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;

    if (!validEmail.test(email)) return 'Ingrese un correo válido.';

    return null;
}

// validatePassword
export const validatePassword = (password: string) => {
    if (password.length < 1) return false;

    if (password.length < 8) return false;

    return true;
}

export const getPasswordErrorMessage = (password: string) => {
    if (password.length < 1) return 'Campo obligatorio';

    if (password.length < 8) return 'La contraseña debe tener una longitud de 8 caracteres como mínimo.';

    return null;
}

export const validateCardCode = (value: string) => {
    if (value.length < 1) return false;

    let validCardCode = /^CL\d{5}$/;

    if (!validCardCode.test(value)) return false;

    return true;
}

export const getCardCodeErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    let validCardCode = /^CL\d{5}$/;

    if (!validCardCode.test(value)) return 'El formato del código de cliente debe ser "CL" seguido de 5 dígitos';

    return null;
}

export const validateCardFName = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 3) return false;

    return true;
}

export const getCardFNameErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 3) return 'Ingrese una razón social válida';

    return null; 
}

export const validateCardName = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 10) return false;

    return true;
}

export const getCardNameErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 10) return 'Ingrese un nombre de contribuyente válido';

    return null; 
}

export const validateNit = (value: string) => {
    if (value.length < 1) return false;

    if (value.length !== 9 && value.length !== 14) return false;

    return true;
}

export const getNitErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length !== 9 && value.length !== 14) return 'El N.I.T. debe contener 14 dígitos. En el caso de utilizar el número de D.U.I. este debe tener 9 dígitos'

    return true;
}

export const validateNrc = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 6) return false;

    return true;
}

export const getNrcErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 6) return 'El N.R.C. debe estar formado por al menos 6 dígitos';

    return null;
}

export const validateEconomicActivity = (value: string) => {
    if (value.length < 1) return false;

    return true;
}

export const getEconomicActivityErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    return null;
}

export const validateAddress = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 12) return false;

    return true;
}

export const getAddressErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 12) return 'Ingrese una dirección válida';

    return null;
}

export const validateDepartment = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 6) return false;

    return true;
}

export const getDepartmentErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 6) return 'Ingrese un departamento válido';

    return null;
}

export const validateMunicipality  = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 6) return false;

    return true;
}

export const getMunicipalityErrorMessage = (value: string) => {
    if (value.length < 1) return 'Campo obligatorio';

    if (value.length < 6) return 'Ingrese un municipio válido';

    return null;
}

export const validateContactPerson = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 5) return false;

    return true;
}

export const getContactPersonErrorMessage = (value: string) => {
    if (value.length < 1) return "Campo obligatorio";

    if (value.length < 5) return "Ingrese una persona de contacto válida";

    return null;
}

export const validateContactPhone = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 8) return false;

    return true;
}

export const getContactPhoneErrorMessage = (value: string) => {
    if (value.length < 1) return "Campo obligatorio";

    if (value.length < 8) return "Ingrese un número de contacto válido";

    return null;
}

export const validateBusinessType = (value: string) => {
    if (value.length < 1) return false;

    if (value.length < 5) return false;

    return true;
}

export const getBusinessTypeErrorMessage = (value: string) => {
    if (value.length < 1) return "Campo obligatorio";

    if (value.length < 5) return "Ingrese un tipo de empresa válido";

    return null;
}