import { Timestamp } from "firebase/firestore";

export const formatTimestampToLocaleDate = (timestamp: Timestamp) => {
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours() % 12 || 12).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

    return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}

export function addDaysToDate(date: Date, days: number) {
    let nuevaFecha = new Date(date);
    nuevaFecha.setDate(nuevaFecha.getDate() + days);
    let dia = ("0" + nuevaFecha.getDate()).slice(-2);
    let mes = ("0" + (nuevaFecha.getMonth() + 1)).slice(-2);
    let año = nuevaFecha.getFullYear();
    return `${dia}/${mes}/${año}`;
}

export function convertTimestampToDate(timestamp: Timestamp) {
    let date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    let dia = ("0" + date.getDate()).slice(-2);
    let mes = ("0" + (date.getMonth() + 1)).slice(-2);
    let año = date.getFullYear();
    return `${dia}/${mes}/${año}`;
}

export function formatDateToLocaleDateString(date: Date) {
    let dia = ("0" + date.getDate()).slice(-2);
    let mes = ("0" + (date.getMonth() + 1)).slice(-2);
    let año = date.getFullYear();
    return `${dia}/${mes}/${año}`;
}

export function formatDateToLocaleFullDateString(date: Date) {
    let dia = ("0" + date.getDate()).slice(-2);
    let mes = ("0" + (date.getMonth() + 1)).slice(-2);
    let año = date.getFullYear();
    let hours = String(date.getHours() % 12 || 12).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');
    return `${dia}/${mes}/${año}_${hours}:${minutes}:${seconds}`;
}

export function formatDateToISODateString(date: Date) {
    let dia = ("0" + date.getDate()).slice(-2);
    let mes = ("0" + (date.getMonth() + 1)).slice(-2);
    let año = date.getFullYear();
    return `${año}-${mes}-${dia}`;
}

export function formatISODateStringToDate(ISODate: string, endDay: boolean) {
    const [year, month, day] = ISODate.split("-");

    if (year && month && day && 
        !isNaN(Number(year)) && !isNaN(Number(month)) && !isNaN(Number(day))
    ) {
        if (endDay) {
            return new Date(Number(year), (Number(month) - 1), Number(day), 23, 59, 59);
        } else {
            return new Date(Number(year), (Number(month) - 1), Number(day), 0, 0, 0);
        }
    } else {
        return null;
    }
}

export function formateDateStringToDate(stringDate: string, endDay: boolean) {
    const [day, month, year] = stringDate.split("/");

    if (day && month && year &&
        !isNaN(Number(day)) && !isNaN(Number(month)) && !isNaN(Number(year))
    ) {
        if (endDay) {
            return new Date(Number(year), (Number(month) - 1), Number(day), 23, 59, 59);
        } else {
            return new Date(Number(year), (Number(month) - 1), Number(day), 0, 0, 0);
        }
    } else {
        return null;
    }
}

export function getDifferenceInDays(date1: Date, date2: Date): number {
    const msInOneDay = 24 * 60 * 60 * 1000; // Milisegundos en un día
    const diffInMs = Math.abs(date2.getTime() - date1.getTime()); // Diferencia en milisegundos
    return Math.floor(diffInMs / msInOneDay); // Convertir a días y redondear hacia abajo
  }