export const ImagotipoLogout = () => {
    return (
        <svg width="25" height="30" viewBox="0 0 25 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_686_374)">
                <path d="M16.9004 1.5H4.90039C3.24354 1.5 1.90039 2.84314 1.90039 4.5V25.5C1.90039 27.1569 3.24354 28.5 4.90039 28.5H16.9004" stroke="#183555" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M22.9004 15L16.9004 9M22.9004 15L16.9004 21M22.9004 15H7.90039" stroke="#183555" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_686_374">
                    <rect width="24" height="30" fill="white" transform="translate(0.400391)" />
                </clipPath>
            </defs>
        </svg>

    )
}