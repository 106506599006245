export const LogoHorizontalPremierTools = () => {
    return (
        <svg width="130" height="30" viewBox="0 0 130 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_286_50)">
                <path d="M0 0.186279V7.99691H15.2092V30.0001H21.754V8.75868H20.111V28.507H16.957V5.92152H1.91679V2.17025H19.1442L20.3477 0.186279H0Z" fill="#003366" />
                <path d="M17.9612 5.3121L20.9598 0.108398L35.0399 0.176111C35.9932 0.179496 36.7606 0.954804 36.7606 1.90955V6.24315C36.7606 7.20128 35.9865 7.97658 35.0298 7.97658H19.4386V27.7791H17.7246V5.98245H34.2827C34.2827 5.98245 34.9047 4.99724 34.8675 4.04926C34.8269 3.0505 34.2827 2.23457 34.2827 2.23457H21.7509L20.0099 5.31549H17.9612V5.3121Z" fill="#46F4CB" />
                <path d="M45.898 7.48223V11.5517H43.5215V0H48.021C48.8865 0 49.6505 0.159124 50.3063 0.477373C50.9621 0.795621 51.4692 1.24591 51.8242 1.83162C52.1791 2.41733 52.3549 3.08092 52.3549 3.82575C52.3549 4.95655 51.9695 5.85036 51.1954 6.50378C50.4212 7.15721 49.3496 7.48223 47.9838 7.48223H45.9014H45.898ZM45.898 5.55242H48.021C48.6498 5.55242 49.1299 5.40345 49.4578 5.1089C49.7857 4.81436 49.9513 4.39115 49.9513 3.8393C49.9513 3.28744 49.7857 2.81684 49.451 2.46812C49.1163 2.1194 48.66 1.93996 48.0717 1.9298H45.8913V5.55581L45.898 5.55242Z" fill="#003366" />
                <path d="M61.506 7.3231H59.6129V11.5517H57.2363V0H61.5229C62.8853 0 63.9366 0.304706 64.677 0.914118C65.4173 1.52353 65.7858 2.38348 65.7858 3.49396C65.7858 4.28281 65.6168 4.93962 65.2753 5.46439C64.9339 5.98917 64.4167 6.40898 63.727 6.72046L66.2219 11.4434V11.5551H63.6696L61.506 7.32649V7.3231ZM59.6129 5.3933H61.5297C62.128 5.3933 62.5878 5.24094 62.9157 4.93624C63.2436 4.63153 63.4059 4.21171 63.4059 3.68017C63.4059 3.14863 63.2504 2.70511 62.9427 2.39364C62.6351 2.08216 62.1584 1.92642 61.5195 1.92642H59.6095V5.3933H59.6129Z" fill="#003366" />
                <path d="M77.7767 6.54779H73.2129V9.64225H78.5678V11.5551H70.833V0H78.5509V1.9298H73.2096V4.68232H77.7733V6.54779H77.7767Z" fill="#003366" />
                <path d="M86.3128 0L89.2776 8.37942L92.2254 0H95.3457V11.5517H92.9624V8.39296L93.199 2.94211L90.0855 11.5517H88.4527L85.346 2.94888L85.5826 8.39296V11.5517H83.2061V0H86.3128Z" fill="#003366" />
                <path d="M103.195 11.5517H100.818V0H103.195V11.5517Z" fill="#003366" />
                <path d="M115.585 6.54779H111.022V9.64225H116.376V11.5551H108.642V0H116.359V1.9298H111.018V4.68232H115.582V6.54779H115.585Z" fill="#003366" />
                <path d="M125.287 7.3231H123.394V11.5517H121.018V0H125.304C126.667 0 127.718 0.304706 128.458 0.914118C129.199 1.52353 129.567 2.38348 129.567 3.49396C129.567 4.28281 129.398 4.93962 129.057 5.46439C128.715 5.98917 128.198 6.40898 127.508 6.72046L130.003 11.4434V11.5551H127.451L125.287 7.32649V7.3231ZM123.394 5.3933H125.311C125.909 5.3933 126.369 5.24094 126.697 4.93624C127.025 4.63153 127.187 4.21171 127.187 3.68017C127.187 3.14863 127.032 2.70511 126.724 2.39364C126.416 2.08216 125.94 1.92642 125.301 1.92642H123.391V5.3933H123.394Z" fill="#003366" />
                <path d="M52.9161 20.2156H49.3834V29.8409H47.0069V20.2156H43.5215V18.2858H52.9195V20.2156H52.9161Z" fill="#003366" />
                <path d="M66.7697 24.3257C66.7697 25.4633 66.5703 26.4586 66.168 27.3186C65.7657 28.1785 65.191 28.8353 64.4439 29.3026C63.6968 29.7698 62.8381 30 61.8713 30C60.9044 30 60.0627 29.7698 59.3122 29.3093C58.5617 28.8489 57.9802 28.1921 57.5678 27.3389C57.1554 26.4857 56.9458 25.5005 56.9424 24.39V23.8179C56.9424 22.6803 57.1486 21.6781 57.5576 20.8148C57.9667 19.9515 58.5448 19.2845 59.2919 18.8241C60.039 18.3636 60.8943 18.13 61.8544 18.13C62.8144 18.13 63.6697 18.3602 64.4168 18.8241C65.1639 19.2879 65.742 19.9515 66.1511 20.8148C66.5601 21.6781 66.7663 22.6769 66.7663 23.8111V24.3257H66.7697ZM64.3627 23.8009C64.3627 22.5889 64.1464 21.668 63.7137 21.0383C63.281 20.4085 62.6623 20.0937 61.8611 20.0937C61.0599 20.0937 60.448 20.4051 60.0153 21.0247C59.5826 21.6443 59.3629 22.5584 59.3561 23.7569V24.3189C59.3561 25.4971 59.5725 26.4146 60.0052 27.0647C60.4379 27.7147 61.0599 28.0397 61.8746 28.0397C62.6894 28.0397 63.2843 27.7249 63.7137 27.0985C64.143 26.4722 64.3594 25.5581 64.3627 24.3562V23.7942V23.8009Z" fill="#003366" />
                <path d="M81.218 24.3257C81.218 25.4633 81.0185 26.4586 80.6162 27.3186C80.2139 28.1785 79.6392 28.8353 78.8921 29.3026C78.145 29.7698 77.2863 30 76.3195 30C75.3527 30 74.5109 29.7698 73.7604 29.3093C73.0099 28.8489 72.4285 28.1921 72.016 27.3389C71.6036 26.4857 71.394 25.5005 71.3906 24.39V23.8179C71.3906 22.6803 71.5968 21.6781 72.0059 20.8148C72.4149 19.9515 72.993 19.2845 73.7401 18.8241C74.4872 18.3636 75.3425 18.13 76.3026 18.13C77.2627 18.13 78.118 18.3602 78.8651 18.8241C79.6122 19.2879 80.1903 19.9515 80.5993 20.8148C81.0084 21.6781 81.2146 22.6769 81.2146 23.8111V24.3257H81.218ZM78.811 23.8009C78.811 22.5889 78.5946 21.668 78.1619 21.0383C77.7292 20.4085 77.1106 20.0937 76.3094 20.0937C75.5082 20.0937 74.8963 20.4051 74.4636 21.0247C74.0309 21.6443 73.8111 22.5584 73.8044 23.7569V24.3189C73.8044 25.4971 74.0207 26.4146 74.4534 27.0647C74.8861 27.7147 75.5082 28.0397 76.3229 28.0397C77.1376 28.0397 77.7326 27.7249 78.1619 27.0985C78.5912 26.4722 78.8076 25.5581 78.811 24.3562V23.7942V23.8009Z" fill="#003366" />
                <path d="M88.568 27.9279H93.6151V29.8408H86.1914V18.2891H88.568V27.9313V27.9279Z" fill="#003366" />
                <path d="M104.206 26.8073C104.206 26.357 104.047 26.0117 103.729 25.7713C103.411 25.5309 102.843 25.277 102.019 25.0096C101.194 24.7421 100.541 24.478 100.061 24.2207C98.7496 23.5131 98.0971 22.5584 98.0971 21.3565C98.0971 20.7335 98.2729 20.1749 98.6245 19.6874C98.9761 19.1998 99.4798 18.8173 100.139 18.5396C100.798 18.262 101.535 18.1266 102.353 18.1266C103.171 18.1266 103.912 18.2756 104.557 18.5735C105.203 18.8714 105.703 19.2946 106.058 19.8397C106.413 20.3848 106.593 21.0044 106.593 21.695H104.216C104.216 21.1669 104.05 20.7538 103.716 20.4627C103.381 20.1715 102.914 20.0225 102.313 20.0225C101.711 20.0225 101.282 20.1444 100.957 20.3916C100.633 20.6387 100.474 20.9604 100.474 21.3632C100.474 21.739 100.663 22.0539 101.042 22.3078C101.42 22.5618 101.975 22.7987 102.708 23.0222C104.06 23.4285 105.044 23.9363 105.663 24.539C106.282 25.1416 106.589 25.8932 106.589 26.7938C106.589 27.7925 106.211 28.578 105.457 29.1468C104.703 29.7156 103.685 30 102.407 30C101.522 30 100.71 29.8375 99.9835 29.5124C99.2567 29.1874 98.6989 28.7405 98.3169 28.1751C97.9349 27.6097 97.7422 26.9529 97.7422 26.2081H100.125C100.125 27.4844 100.886 28.1209 102.407 28.1209C102.972 28.1209 103.415 28.0058 103.729 27.7756C104.044 27.5454 104.206 27.2238 104.206 26.8107V26.8073Z" fill="#003366" />
            </g>
            <defs>
                <clipPath id="clip0_286_50">
                    <rect width="130" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}