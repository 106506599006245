export const ImagotipoIvaCards = () => {
    return (
        <svg width="27" height="23" viewBox="0 0 35 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_286_341)">
                <path d="M34.0148 0.164185H1.20197C0.874296 0.164185 0.608398 0.430082 0.608398 0.757754V21.7422C0.608398 22.0699 0.874296 22.3358 1.20197 22.3358H34.0148C34.3425 22.3358 34.6084 22.0699 34.6084 21.7422V0.757754C34.6084 0.430082 34.3425 0.164185 34.0148 0.164185ZM33.4213 21.1486H1.79554V1.35132H33.4213V21.1473V21.1486Z" fill="#183555" />
                <path d="M9.05703 5.86353H7.59057L6.52832 11.8798H7.99344L9.05703 5.86353Z" fill="#183555" />
                <path d="M9.18911 11.7643C9.3234 11.8865 9.50469 11.9483 9.73299 11.9483C10.0123 11.9483 10.2514 11.857 10.4488 11.6743C10.6462 11.4917 10.7442 11.2715 10.7442 11.015C10.7442 10.8202 10.6784 10.6618 10.5468 10.5396C10.4152 10.4174 10.2379 10.3556 10.015 10.3556C9.72359 10.3556 9.47918 10.4469 9.28177 10.6295C9.08436 10.8122 8.98633 11.0351 8.98633 11.2983C8.98633 11.4863 9.05347 11.6421 9.18777 11.7657L9.18911 11.7643Z" fill="#183555" />
                <path d="M14.9858 11.8798L18.1833 5.86353H16.6228L14.3345 10.4053L13.6415 5.86353H12.0811L13.1527 11.8798H14.9858Z" fill="#183555" />
                <path d="M17.6595 11.297C17.6595 11.485 17.7267 11.6408 17.861 11.7643C17.9953 11.8865 18.1766 11.9483 18.4049 11.9483C18.6842 11.9483 18.9232 11.857 19.1206 11.6743C19.3181 11.4917 19.4161 11.2715 19.4161 11.015C19.4161 10.8202 19.3503 10.6618 19.2187 10.5396C19.0871 10.4174 18.9098 10.3556 18.6869 10.3556C18.3955 10.3556 18.1511 10.4469 17.9536 10.6295C17.7562 10.8122 17.6582 11.0351 17.6582 11.2983L17.6595 11.297Z" fill="#183555" />
                <path d="M21.8331 10.8176H24.0785L24.2504 11.8798H25.8014L24.6882 5.86353H22.992L19.7529 11.8798H21.2865L21.8345 10.8176H21.8331ZM23.5561 7.47503L23.9079 9.70293H22.4079L23.5561 7.47503Z" fill="#183555" />
                <path d="M27.1373 11.9483C27.4166 11.9483 27.6557 11.857 27.8531 11.6743C28.0505 11.4917 28.1485 11.2715 28.1485 11.015C28.1485 10.8202 28.0827 10.6618 27.9511 10.5396C27.8195 10.4174 27.6422 10.3556 27.4193 10.3556C27.1279 10.3556 26.8835 10.4469 26.6861 10.6295C26.4887 10.8122 26.3906 11.0351 26.3906 11.2983C26.3906 11.4863 26.4578 11.6421 26.5921 11.7657C26.7264 11.8879 26.9076 11.9496 27.1359 11.9496L27.1373 11.9483Z" fill="#183555" />
                <path d="M4.25481 13.2416H21.2602C21.5073 13.2416 21.7074 13.4417 21.7074 13.6888V16.1893C21.7074 16.4364 21.5073 16.6365 21.2602 16.6365H4.25481C4.00771 16.6365 3.80762 16.4364 3.80762 16.1893V13.6888C3.80762 13.4417 4.00771 13.2416 4.25481 13.2416Z" fill="#183555" />
                <path d="M30.9623 13.2416H23.998C23.751 13.2416 23.5508 13.4418 23.5508 13.6888V16.1893C23.5508 16.4363 23.751 16.6365 23.998 16.6365H30.9623C31.2093 16.6365 31.4095 16.4363 31.4095 16.1893V13.6888C31.4095 13.4418 31.2093 13.2416 30.9623 13.2416Z" fill="#183555" />
            </g>
            <defs>
                <clipPath id="clip0_286_341">
                    <rect width="34" height="22.1716" fill="white" transform="translate(0.608398 0.164185)" />
                </clipPath>
            </defs>
        </svg>
    )
}
