import { IonIcon } from "@ionic/react"
import { AppColor } from "types"
import { getButtonColor } from "utils"

export const SolidButton = (
    {   text, 
        backgroundColor,
        disabled,
        onClickHandler,
        icon 
    }: 
    {   text: string, 
        backgroundColor: AppColor,
        disabled: boolean,
        onClickHandler: (event: any) => void,
        icon?: string
    }
) => {
    const buttonStyles = {
        backgroundColor: getButtonColor(backgroundColor),
    }

    return (
        <button 
            onClick={onClickHandler}
            disabled={disabled}
            className={`w-full hover:opacity-90 text-[0.9rem] px-4 py-2 text-light font-bold rounded-md cursor-pointer flex items-center justify-center gap-2 ${disabled && "opacity-70"}`}
            style={buttonStyles}
        >
            {icon && <IonIcon icon={icon} className="text-[18px]" /> }
            {text}
        </button>
    )
}
