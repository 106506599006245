import { IonIcon } from "@ionic/react";
import { helpCircle } from 'ionicons/icons';

export const QuestionModal = ({ question, acceptClickHandler, cancelClickHandler }: { question: string, acceptClickHandler: () => void, cancelClickHandler: () => void }) => {
    return (
        <div className='w-screen h-screen fixed flex justify-center items-center z-40'>
            <div className="bg-white w-1/4 flex flex-col items-center p-4 rounded-md">
                <div className='bg-white'>
                    <IonIcon icon={helpCircle} className="text-primary text-[80px]" />
                </div>

                <div className='w-full mb-4 text-center' dangerouslySetInnerHTML={{ __html: question }}>
                </div>

                <div className="w-full flex">
                    <div className='w-1/2 px-4'>
                        <button
                            onClick={acceptClickHandler}
                            className='bg-success text-light font-bold px-4 py-2 rounded-md w-full'
                        >
                            Aceptar
                        </button>
                    </div>

                    <div className='w-1/2 px-4'>
                        <button
                            onClick={cancelClickHandler}
                            className='bg-danger text-light font-bold px-4 py-2 rounded-md w-full'
                        >
                            Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}