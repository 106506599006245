import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AuthStatus } from 'types'

interface AuthState {
    authStatus: AuthStatus,
}

const initialState: AuthState = {
    authStatus: 'checking',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state) => {
            state.authStatus = "authenticated";
        },
        logout: (state) => {
            state.authStatus = "not-authenticated";
        },
        checkingCredentials: (state) => {
            state.authStatus = "checking";
        },
    },
})

// Action creators are generated for each case reducer function
export const 
{ 
    login, 
    logout, 
    checkingCredentials, 
} = authSlice.actions