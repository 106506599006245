import { IonContent, IonPage } from "@ionic/react"
import { SidebarMenu } from "components";
import { useRef, useState } from "react";
import { SellersOrders } from "../components/SellersOrders";
import { CustomersVisitControl } from "../components/CustomersVisitControl";
import { formatDateToLocaleDateString } from "utils";

export const SellersControlPage = () => {
    const today = new Date()
    const todayAux = new Date(new Date().setHours(0, 0, 0, 0));

    const [showOrdersDetails, setShowOrdersDetails] = useState<boolean>(false);

    /// estado que almacena el inicio del rango de fechas de los benchmarks consultadas
    const [startDate, setStartDate] = useState<Date>(new Date(todayAux.setDate(todayAux.getDate() - 7)));

    // estado que almacena el fin del rango de fechas de los benchmarks consultadas
    const [finishDate, setFinishDate] = useState<Date>(new Date());

    // estado que almacena la fecha de inicio escogida en el calendario de los filtros de búsqueda
    const [startDateSearchFilter, setStartDateSearchFilter] = useState<Date>(new Date(startDate));

    // estado que almacena la fecha de fin escogida en el calendario de los filtros de búsqueda
    const [finishDateSearchFilter, setFinishDateSearchFilter] = useState<Date>(new Date());

    // estado para saber si el rango de fecha de consulta de los benchmarks ha cambiado
    // para asegurar que el estado sea único se almacenará la fecha y la hora en la que se modifica dicho rango
    const [hasDatesChanged, setHasDatesChanged] = useState<number>(1);

    const contentRef = useRef<HTMLIonContentElement>(null);

    return (
        <IonPage>
            <IonContent ref={contentRef}>
                <div className="w-full h-screen grid grid-cols-12">
                    {/* Menú lateral */}
                    <div className="col-span-2 border-r-2 border-light">
                        <SidebarMenu pageName="sellers_control" />
                    </div>

                    <div className="w-full h-screen flex flex-col flex-grow col-span-10">
                        <div className="w-full flex justify-between border-b-2 border-tertiary px-2 py-3">
                            <div className="flex items-center gap-4">
                                <p className="text-primary font-bold text-lg">Control de Vendedores</p>

                                {
                                    showOrdersDetails &&
                                    <button
                                        onClick={() => setShowOrdersDetails(false)}
                                        className="bg-tertiary px-4 py-1 rounded-md font-semibold"
                                    >Ver resumen</button>
                                }

                                {
                                    !showOrdersDetails &&
                                    <button
                                        onClick={() => setShowOrdersDetails(true)}
                                        className="bg-tertiary px-4 py-1 rounded-md font-semibold"
                                    >Ver detalles</button>
                                }
                            </div>


                            <p className="font-semibold text-lg">Del {formatDateToLocaleDateString(startDate)} al {formatDateToLocaleDateString(finishDate)}</p>
                        </div>

                        <CustomersVisitControl
                            showOrdersDetails={showOrdersDetails}
                            contentRef={contentRef}
                            today={today}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            startDateSearchFilter={startDateSearchFilter}
                            setStartDateSearchFilter={setStartDateSearchFilter}
                            finishDate={finishDate}
                            setFinishDate={setFinishDate}
                            finishDateSearchFilter={finishDateSearchFilter}
                            setFinishDateSearchFilter={setFinishDateSearchFilter}
                            hasDatesChanged={hasDatesChanged}
                            setHasDatesChanged={setHasDatesChanged}
                        />

                        <SellersOrders
                            showOrdersDetails={showOrdersDetails}
                            contentRef={contentRef}
                            today={today}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            startDateSearchFilter={startDateSearchFilter}
                            setStartDateSearchFilter={setStartDateSearchFilter}
                            finishDate={finishDate}
                            setFinishDate={setFinishDate}
                            finishDateSearchFilter={finishDateSearchFilter}
                            setFinishDateSearchFilter={setFinishDateSearchFilter}
                            hasDatesChanged={hasDatesChanged}
                            setHasDatesChanged={setHasDatesChanged}
                        />
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}