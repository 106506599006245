import '@ionic/react/css/core.css';
import './index.css';
import { IonApp, setupIonicReact } from '@ionic/react';
import { MainRouter } from "routers";

setupIonicReact({
  mode: "ios"
});

function App() {
  return (
    <IonApp>
      <MainRouter />
    </IonApp>
  );
}

export default App;
