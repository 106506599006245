import { UseIonRouterResult } from "@ionic/react";
import { Dispatch } from "@reduxjs/toolkit";
import { FirebaseAuth } from "firebase-config";
import { LoadSettingsPayload, SaveUserInformationPayload } from "interfaces";
import { checkingCredentials, login, logout } from "store/auth";
import { loadSettings } from "store/settings";
import { saveUserInformation } from "store/user";
import { getDocumentById } from "utils";

export async function validateAuth(dispatch: Dispatch) {
    try {
        if (FirebaseAuth.currentUser) {
            const { uid, email } = FirebaseAuth.currentUser;

            const accountDoc = await getDocumentById("accounts", uid);

            if (accountDoc.status === 200 && accountDoc.data) {
                const saveUserInformationPayload: SaveUserInformationPayload = {
                    uid,
                    email: email ?? accountDoc.data.email ?? null,
                    username: `${accountDoc.data.name} ${accountDoc.data.last_name}`,
                    role: accountDoc.data.role ?? null,
                    vendorCode: accountDoc.data.vendor_code ?? null,
                }

                dispatch(saveUserInformation(saveUserInformationPayload));

                // Recuperamos las configuraciones del dashboard
                const settingsDoc = await getDocumentById("configurations", "general");

                const settingsDashboard = await getDocumentById("dashboardsettings", "general");

                if (settingsDoc.status === 200 && settingsDoc.data && settingsDoc.data.dashboard && settingsDashboard.status === 200 && settingsDashboard.data) {
                    let permissions = [];

                    if (settingsDashboard.data.access_permits) {
                        permissions = settingsDashboard.data.access_permits[accountDoc.data.role] ?? [];
                    }

                    const loadSettingsPayload: LoadSettingsPayload = {
                        accessPermits: permissions,
                        dynamicDashboardPeriod: {
                            month: settingsDoc.data.dashboard.mounth ?? null,
                            year: settingsDoc.data.dashboard.year ?? null,
                        }
                    }

                    dispatch(loadSettings(loadSettingsPayload));
                }

                dispatch(login());
            } else {
                throw new Error("Ha ocurrido un error durante el inicio de sesión");
            }
        } else {
            throw new Error();
        }
    } catch (error: any) {
        dispatch(logout());
    }
}

export async function signOutSession(dispatch: Dispatch, ionRouter: UseIonRouterResult, errorMessage?: string) {
    dispatch(checkingCredentials());
    // stopFirebaseAuthListeners();
    // stopFirebaseListeners();

    // if (errorMessage) {
    //     dispatch(changeAuthErrorMessage(errorMessage));
    // }

    // Limpiamos toda la información almacenada en el store de Redux
    // deleteReduxInformation(dispatch);

    await FirebaseAuth.signOut().finally(() => {
        dispatch(logout());
        ionRouter.push("/auth/signin", "root", "replace");
    })
}