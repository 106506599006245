import * as echarts from "echarts";
import { useEffect, useRef } from "react";
import { CircularGraphicElement } from "../interfaces/dynamic-dashboard.interfaces";

const getGraphicColor = (value: number) => {
    if (value < 54) {
        return '#e76262';
    } else if (value < 70) {
        return '#eca336';
    } else if (value < 87) {
        return '#f9cf4a';
    } else {
        return '#3ece80';
    }
}

export const AchieveGoalsTab = ({ goalsVendor, channels }: { goalsVendor: any, channels: CircularGraphicElement[] }) => {
    const chartRef = useRef(null);

    useEffect(() => {
        const chart = echarts.init(chartRef.current, null, { renderer: "svg" });

        configComplianceGraphic(chart);

        return () => {
            chart.dispose();
        }
    }, []);

    // Función para construir el gráfico de cumplimiento de metas
    const configComplianceGraphic = (chart: echarts.ECharts) => {
        const pointerBorderColor = getGraphicColor(goalsVendor.details.percentage);

        chart.setOption({
            series: [
                {
                    data: [
                        {
                            value: goalsVendor.details.percentage,
                            name: '%'
                        }
                    ],
                    type: 'gauge',
                    radius: '100%',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 100,
                    splitNumber: 3,
                    pointer: {
                        icon: 'circle',
                        length: '12%',
                        width: 50,
                        offsetCenter: [0, '-90%'],
                        itemStyle: {
                            color: '#FFFFFF',
                            borderColor: pointerBorderColor,
                            borderWidth: 5,
                            shadowColor: 'rgba(10, 31, 68, 0.5)',
                            shadowBlur: 2,
                            shadowOffsetY: 1,
                        },
                    },
                    axisLine: {
                        show: true,
                        roundCap: true,
                        lineStyle: {
                            width: 9,
                            color: [
                                [0.5, '#e76262'],
                                [0.54],                               
                                [0.66, '#eca336'],
                                [0.7],
                                [0.83, '#f9cf4a'],
                                [0.87],
                                [1, '#3ece80'],
                            ],
                        },
                    },
                    axisTick: {
                        length: 2,
                        lineStyle: {
                            color: '#8a94a6',
                            width: 2,
                        },
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        show: false,
                    },
                    title: {
                        show: false,
                    },
                    detail: {
                        rich: {
                            header: {
                                fontSize: 36,
                                fontWeight: 700,
                                fontFamily: 'Open Sans',
                                color: '#0a1f44',
                            },
                            subHeader: {
                                fontSize: 16,
                                fontWeight: 400,
                                fontFamily: 'Open Sans',
                                color: '#8a94a6',
                            },
                        },
                        offsetCenter: [0, '-20%'],
                        valueAnimation: true,
                    },
                },
            ]
        });
    }

    return (
        <div className="col-span-6 grid grid-cols-2 gap-5 pb-8">
            <div className="flex items-center justify-center bg-white rounded-md logo-card max-h-max relative">
                <div ref={chartRef} className={`flex justify-center w-[500px] h-[300px] gauge-container mt-6`} />

                <div className="w-full h-full flex items-center justify-center absolute px-4">
                    <div className="mt-40 pt-4 border-t border-tertiary w-full flex flex-col items-center gap-1">
                        <p className="font-semibold">Cumplimiento de la meta</p>
                        <p className="font-bold">{goalsVendor.details.percentage?.toFixed(2)}/100</p>
                    </div>
                </div>
            </div>

            <div className="p-4 flex flex-col gap-4 bg-white rounded-md logo-card">
                <div className="grid grid-cols-2 font-bold w-[90%]">
                    <p className="w-full">Meta</p>
                    <p className="w-full text-end">% de Cumplimiento</p>
                </div>

                <div className="flex flex-col gap-2">
                    {
                        channels.map((item: CircularGraphicElement) => (
                            <div key={item.name} className="grid grid-cols-2 w-[90%] pb-2 border-b border-tertiary last:border-b-0">
                                <p className="w-full uppercase">{item.name}</p>
                                <p className="w-full text-end font-bold" style={{ color: getGraphicColor(item.value) }} >{item.value}%</p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}