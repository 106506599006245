import { useEffect, useMemo, useState } from "react"

export const useForm = (initialForm: any, formValidations: any = {}) => {
    const [formState, setFormState] = useState(initialForm);
    const [formValidation, setFormValidation] = useState<any>(formValidations);

    const createValidators = () => {
        const formCheckedValues: any = {};

        for (const formField of Object.keys(formValidations)) {
            const [fn, errorMessage] = formValidations[formField];

            formCheckedValues[`${formField}Valid`] = fn(formState[formField]) ? null : (typeof (errorMessage) === 'string' ? errorMessage : errorMessage(formState[formField]));
        }

        setFormValidation(formCheckedValues);
    }

    useEffect(() => {
        createValidators();
    }, [formState]);

    const isFormValid = useMemo(() => {
        for (const formValue of Object.keys(formValidation)) {
            if (formValidation[formValue] !== null) return false;
        }
        return true;
    }, [formValidation]);

    const resetForm = () => {
        setFormState(initialForm);
    }

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>, patternValidator?: RegExp, inputValueFormatter?: (value: string, isDeleting: boolean) => string) => {
        let value = event.target.value;

        // validamos que el texto ingresado cumpla con el patrón de validación
        if (patternValidator && !patternValidator.test(value)) return;

        if (inputValueFormatter) {
            let deleting = formState[event.target.name].length > value.length ? true : false;

            value = inputValueFormatter(value, deleting);
        }

        setFormState({
            ...formState,
            [event.target.name]: value
        });
    }

    const onSelectFieldInputChange = (fieldName: string, newValue: string) => {
        setFormState({
            ...formState,
            [fieldName]: newValue
        });
    }
 
    
    return {
        ...formState,
        formState,
        onInputChange,
        onSelectFieldInputChange,
        resetForm,
        setFormState,

        ...formValidation,
        isFormValid
    }
}