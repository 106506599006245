import EmptyFolder from 'assets/img/app/empty-folder.png';
import { IonDatetime, IonIcon, IonSpinner } from "@ionic/react"
import { SetStateAction, useEffect, useState } from "react";
import { convertTimestampToDate, formatDateToISODateString, formatDateToLocaleDateString, formatISODateStringToDate, getOrderDistance, getSearchFilters, getSearchFiltersValues } from "utils";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Pagination } from '@mui/material';
import { IconSeller } from 'assets';
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FirebaseDB } from 'firebase-config';
import { SolidButton } from 'components';
import { caretDown } from 'ionicons/icons';
import { SearchFilterItem, SearchFilters } from 'interfaces';

interface SellerRecord {
    key: string,
    vendorName: string,
    sellerCode: string,
    date: string,
    orderNumber: string,
    branchProximity: string,
    totalSold: string,
    nonPurchaseReason: string | null
}

type CustomerVisitControlFilters = "vendorName";

const initialSearchFilters: Record<CustomerVisitControlFilters, SearchFilters> = {
    vendorName: {
        allChecked: true,
        allHidden: false,
        items: [],
    },
}

export const SellersOrders = (
    {
        showOrdersDetails,
        contentRef,
        today,
        startDate,
        setStartDate,
        startDateSearchFilter,
        setStartDateSearchFilter,
        finishDate,
        setFinishDate,
        finishDateSearchFilter,
        setFinishDateSearchFilter,
        hasDatesChanged,
        setHasDatesChanged
    }:
        {
            showOrdersDetails: boolean,
            contentRef: React.RefObject<HTMLIonContentElement>,
            today: Date,
            startDate: Date,
            setStartDate: React.Dispatch<SetStateAction<Date>>,
            startDateSearchFilter: Date,
            setStartDateSearchFilter: React.Dispatch<SetStateAction<Date>>,
            finishDate: Date,
            setFinishDate: React.Dispatch<SetStateAction<Date>>,
            finishDateSearchFilter: Date,
            setFinishDateSearchFilter: React.Dispatch<SetStateAction<Date>>,
            hasDatesChanged: number,
            setHasDatesChanged: React.Dispatch<SetStateAction<number>>
        }
) => {

    // estado para almacenar los benchmarks recuperados
    const [sellersData, setSellersData] = useState<any[]>([]);
    const [filteredSellersData, setFilteredSellersData] = useState<any[]>([]);

    // estados para almacenar los datos del reporte
    const [report, setReport] = useState<SellerRecord[][]>([]);
    const [visibleReport, setVisibleReport] = useState<SellerRecord[]>([]);

    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(10);

    const [loadingReport, setLoadingReport] = useState<boolean>(true);
    const [filteringReport, setFilteringReport] = useState<boolean>(false);

    // estados para almacenar los valores que aparecerán en los filtros de búsqueda
    const [searchFilters, setSearchFilters] = useState<Record<CustomerVisitControlFilters, SearchFilters>>(initialSearchFilters);

    const getInitialData = async () => {
        try {
            setLoadingReport(true);

            const ordersRef = collection(FirebaseDB, "orders");

            const totalOrdersQuery = query(
                ordersRef,
                where("created_at", ">=", startDate),
                where("created_at", "<=", finishDate),
                orderBy("created_at", "desc")
            );

            const ordersSnapshot = await getDocs(totalOrdersQuery);

            if (ordersSnapshot.size > 0) {
                setPaginationCount(Math.ceil(ordersSnapshot.size / recordsPerView));

                const ordersReport = ordersSnapshot.docs.map((doc) => doc.data());
                setSellersData(ordersReport);
                setFilteredSellersData(ordersReport);

                // Obtenemos los filtros de búsqueda
                const sellersArray = getSearchFilters(ordersReport, "vendorName");

                setSearchFilters({
                    vendorName: {
                        allChecked: true,
                        allHidden: false,
                        items: sellersArray,
                    },
                });

                const ordersFilteredReport = ordersReport.slice(0, recordsPerView);

                const sellerReportAux: SellerRecord[][] = [];

                const ordersReportArray: SellerRecord[] = ordersFilteredReport.map((item) => {
                    return {
                        key: item.$key,
                        vendorName: item.vendorName ?? "",
                        sellerCode: item.salesPersonCode ?? "",
                        date: convertTimestampToDate(item.created_at) ?? "",
                        orderNumber: item.numberOrder,
                        branchProximity: getOrderDistance(item.trackingDetails),
                        totalSold: (Number(item.totalIva))?.toLocaleString("es-SV", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                        nonPurchaseReason: item.notesTracking?.notes ?? null,
                    }
                });

                sellerReportAux.push(ordersReportArray);

                setReport(sellerReportAux);
                setVisibleReport(sellerReportAux[currentPage - 1]);
            } else {
                setReport([]);
                setVisibleReport([]);
            }
        } catch (error: any) {
            setReport([]);
            setVisibleReport([]);
        } finally {
            setLoadingReport(false);
        }
    }

    useEffect(() => {
        getInitialData();
    }, [hasDatesChanged]);

    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        let visibleReportAux: SellerRecord[] = [];

        if (report[value - 1] === undefined) {
            const filteredSellers = filteredSellersData.slice(recordsPerView * (value - 1), recordsPerView * value);

            visibleReportAux = filteredSellers.map((item: any) => {
                return {
                    key: item.$key,
                        vendorName: item.vendorName ?? "",
                        sellerCode: item.salesPersonCode ?? "",
                        date: convertTimestampToDate(item.created_at) ?? "",
                        orderNumber: item.numberOrder,
                        branchProximity: getOrderDistance(item.trackingDetails),
                        totalSold: (Number(item.totalIva))?.toLocaleString("es-SV", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                        nonPurchaseReason: item.notesTracking?.notes ?? null,
                }
            });

            let reportAux = report;

            reportAux[value - 1] = visibleReportAux;

            setReport(reportAux);
        } else {
            visibleReportAux = report[value - 1];
        }

        setVisibleReport(visibleReportAux);
        setCurrentPage(value);

        if (contentRef && contentRef.current) {
            contentRef.current.scrollToTop(500);
        }

        setFilteringReport(false);
    }

    const changeStartDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newStartDate = formatISODateStringToDate(stringDate, false);

            if (newStartDate) {
                const diferenciaMilisegundos = Number(finishDateSearchFilter) - Number(newStartDate);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 30) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newStartDate);

                    const newFinishDate = new Date(dateAux.setDate(dateAux.getDate() + 30));

                    if (Number(newFinishDate) > Number(today)) {
                        setFinishDateSearchFilter(today);
                    } else {
                        setFinishDateSearchFilter(newFinishDate)
                    }
                }

                setStartDateSearchFilter(newStartDate);
            }
        }
    }

    const changeFinishDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newFinishDate = formatISODateStringToDate(stringDate, true);

            if (newFinishDate) {
                const diferenciaMilisegundos = Number(newFinishDate) - Number(startDateSearchFilter);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 30) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newFinishDate);

                    const newStartDate = new Date(dateAux.setDate(dateAux.getDate() - 30));

                    setStartDateSearchFilter(newStartDate);
                }

                setFinishDateSearchFilter(newFinishDate);
            }
        }
    }

    const applyDateFilter = () => {
        if ((startDate !== startDateSearchFilter) || (finishDate !== finishDateSearchFilter)) {
            setStartDate(startDateSearchFilter);
            setFinishDate(finishDateSearchFilter);
            setHasDatesChanged(hasDatesChanged + 1);
        }
    }

    const allCheckedChangeHandler = (filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFilters[filterName];
        const newCheckedState = !filterCategory.allChecked;

        const updatedItems = filterCategory.items.map(item => {
            if (item.visible) {
                return { ...item, checked: newCheckedState };
            }
            return item;
        });

        const searchFiltersAux = {
            ...searchFilters,
            [filterName]: {
                ...searchFilters[filterName],
                allChecked: newCheckedState,
                items: updatedItems
            }
        };

        updateFiltersAndBenchmarks(searchFiltersAux, filterName);
    };

    const changeSearchFilterHandler = (event: React.ChangeEvent<HTMLInputElement>, filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFilters[filterName];
        const filterArray = filterCategory.items;

        const indexFilter = filterArray.findIndex(item => item.value === event.target.value);

        if (indexFilter !== -1) {
            const updatedFilter = { ...filterArray[indexFilter], checked: event.target.checked };

            const updatedFilterArray = [
                ...filterArray.slice(0, indexFilter),
                updatedFilter,
                ...filterArray.slice(indexFilter + 1)
            ];

            const searchFiltersAux = {
                ...searchFilters,
                [filterName]: {
                    ...searchFilters[filterName],
                    items: updatedFilterArray
                }
            };

            updateFiltersAndBenchmarks(searchFiltersAux, filterName);
        }
    };

    const updateFiltersAndBenchmarks = (searchFiltersAux: Record<CustomerVisitControlFilters, SearchFilters>, filterName: CustomerVisitControlFilters) => {
        const filterCategory = searchFiltersAux[filterName];
        const checkedFilters = filterCategory.items.filter(item => item.checked).map(item => item.value);
        let newFilteredSellersData = sellersData.filter(item => checkedFilters.includes(item[filterName]));

        for (const key in searchFiltersAux) {
            if (key !== filterName) {
                const foundSearchFilters = getSearchFiltersValues(newFilteredSellersData, key as CustomerVisitControlFilters);

                searchFiltersAux[key as CustomerVisitControlFilters].items = searchFiltersAux[key as CustomerVisitControlFilters].items.map(item => ({
                    ...item,
                    visible: foundSearchFilters.includes(item.value)
                }));
            }

            const allChecked = searchFiltersAux[key as CustomerVisitControlFilters].items.every(item => !item.visible || item.checked);
            const allHidden = searchFiltersAux[key as CustomerVisitControlFilters].items.every(item => !item.visible);

            searchFiltersAux[key as CustomerVisitControlFilters] = {
                ...searchFiltersAux[key as CustomerVisitControlFilters],
                allChecked,
                allHidden,
            }
        }

        const activeFilters = Object.keys(searchFiltersAux).reduce((acc, key) => {
            const filters = searchFiltersAux[key as CustomerVisitControlFilters].items
                .filter(item => item.checked && item.visible)
                .map(item => item.value);
            return { ...acc, [key]: filters };
        }, {} as Record<CustomerVisitControlFilters, string[]>);

        newFilteredSellersData = sellersData.filter(item => {
            return Object.keys(activeFilters).every(key => {
                const filterKey = key as CustomerVisitControlFilters;
                return activeFilters[filterKey].includes(item[filterKey]);
            });
        });

        setSearchFilters(searchFiltersAux);
        setFilteredSellersData(newFilteredSellersData);
        setCurrentPage(1);
        setPaginationCount(Math.ceil(newFilteredSellersData.length / recordsPerView));
    };

    const changeFilteredSellersDataHandler = async () => {
        setFilteringReport(true);

        const ordersFilteredReport = filteredSellersData.slice(0, recordsPerView);

        const sellerReportAux: SellerRecord[][] = [];

        const ordersReportArray: SellerRecord[] = ordersFilteredReport.map((item) => {
            return {
                key: item.$key,
                vendorName: item.vendorName ?? "",
                sellerCode: item.salesPersonCode ?? "",
                date: convertTimestampToDate(item.created_at) ?? "",
                orderNumber: item.numberOrder,
                branchProximity: getOrderDistance(item.trackingDetails),
                totalSold: (Number(item.totalIva))?.toLocaleString("es-SV", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }),
                nonPurchaseReason: item.notesTracking?.notes ?? null,
            }
        });

        sellerReportAux.push(ordersReportArray);

        setReport(sellerReportAux);
        setVisibleReport(sellerReportAux[currentPage - 1]);


        setFilteringReport(false);
    }

    useEffect(() => {
        changeFilteredSellersDataHandler();
    }, [filteredSellersData]);

    return (
        <div id="main-section" className={`w-full h-screen overflow-y-scroll grid grid-cols-10 ${!showOrdersDetails && 'hidden'}`}>
            {
                loadingReport &&
                <div className="col-span-10 w-full h-full flex flex-col items-center justify-center">
                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                </div>
            }

            {
                !loadingReport &&
                <div className="w-full h-full flex flex-col flex-grow col-span-7">
                    {
                        filteringReport &&
                        <div className="w-full h-screen flex flex-col items-center justify-center">
                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                        </div>
                    }

                    {
                        !filteringReport && !(visibleReport.length > 0) &&
                        <div className="h-screen grid grid-cols-8">
                            <div className="col-span-8 flex flex-col items-center gap-4 p-4">
                                <img src={EmptyFolder} alt='empty' className="h-[200px] object-contain" />

                                <p className="text-xl text-center font-semibold text-primary">No se encontraron resultados para tu búsqueda</p>
                            </div>
                        </div>
                    }

                    {
                        !filteringReport && visibleReport.length > 0 &&
                        <div className="w-full h-full flex flex-col">
                            <div className="px-4 pt-4 pb-1 grid grid-cols-6 gap-4 text-mid border-b-2 border-tertiary [&>p]:mt-auto [&>p]:text-primary [&>p]:font-semibold">
                                <p>Vendedor</p>
                                <p>Cód. Vendedor</p>
                                <p>Fecha</p>
                                <p>Número de Orden</p>
                                <p>Proximidad de Local</p>
                                <p>Total Vendido</p>
                            </div>

                            <div className="w-full flex flex-col gap-6">
                                {
                                    visibleReport.map((sellerRecord: SellerRecord) => (
                                        <div
                                            key={sellerRecord.key}
                                            className="w-full px-4 py-3 odd:bg-light items-center grid grid-cols-6 gap-4 text-mid"
                                        >
                                            <div className="w-full flex items-center gap-3">
                                                <div className="w-[18px]">
                                                    <IconSeller />
                                                </div>

                                                <p> {sellerRecord.vendorName}</p>
                                            </div>
                                            <p>{sellerRecord.sellerCode}</p>
                                            <p>{sellerRecord.date}</p>
                                            {
                                                sellerRecord.nonPurchaseReason &&
                                                <div className="w-full flex flex-col text-small">
                                                    <p>Motivo de No Compra</p>
                                                    <p className="font-bold">{sellerRecord.nonPurchaseReason}</p>
                                                </div>
                                            }

                                            {
                                                !sellerRecord.nonPurchaseReason &&
                                                <p>#{sellerRecord.orderNumber}</p>
                                            }
                                            <p>{sellerRecord.branchProximity}</p>
                                            <p>${sellerRecord.totalSold}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    }

                    <div className="w-full flex justify-center mt-auto py-4">
                        <Pagination
                            size="large"
                            color="primary"
                            count={paginationCount}
                            defaultPage={1}
                            page={currentPage}
                            siblingCount={1}
                            boundaryCount={2}
                            onChange={changePaginationHandler}
                        />
                    </div>
                </div>
            }

            {/* Filtros de búsqueda */}
            {
                !loadingReport &&
                <div className="w-full overflow-y-scroll flex p-2 flex-col col-span-3 order-last border-l-2 border-light">
                    <Accordion className="accordion-search-filter">
                        <AccordionSummary
                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="font-semibold text-mid">Fecha</p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="flex flex-col items-center gap-12">
                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Fecha de inicio</p>
                                    <IonDatetime
                                        presentation="date"
                                        value={formatDateToISODateString(startDateSearchFilter)}
                                        max={formatDateToISODateString(today)}
                                        onIonChange={(event) => changeStartDateHandler(event.detail.value)}
                                    />
                                </div>

                                <div className="flex flex-col gap-2">
                                    <p className="font-semibold">Fecha de fin</p>
                                    <IonDatetime
                                        presentation="date"
                                        value={formatDateToISODateString(finishDateSearchFilter)}
                                        max={formatDateToISODateString(today)}
                                        onIonChange={(event) => changeFinishDateHandler(event.detail.value)}
                                    />
                                </div>

                                <div className="w-full xl:w-1/2">
                                    <SolidButton
                                        text="Aplicar"
                                        backgroundColor="primary"
                                        disabled={false}
                                        onClickHandler={applyDateFilter}
                                    />
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion className="accordion-search-filter">
                        <AccordionSummary
                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="font-semibold text-mid">Vendedores</p>
                        </AccordionSummary>

                        <AccordionDetails>
                            <div className="flex flex-col">
                                {
                                    searchFilters.vendorName.allHidden &&
                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                }

                                {
                                    !searchFilters.vendorName.allHidden &&
                                    <FormControlLabel
                                        label={"Todos"}
                                        control=
                                        {
                                            <Checkbox
                                                id={"displays_all"}
                                                name={"displays_all"}
                                                checked={searchFilters.vendorName.allChecked}
                                                onChange={() => allCheckedChangeHandler("vendorName")}
                                            />
                                        }
                                    />
                                }

                                {
                                    !searchFilters.vendorName.allHidden && searchFilters.vendorName.items.map((item: SearchFilterItem) => {
                                        if (item.visible) {
                                            return (
                                                <FormControlLabel
                                                    key={item.value}
                                                    label={item.label}
                                                    control=
                                                    {
                                                        <Checkbox
                                                            id={item.value}
                                                            name={item.value}
                                                            value={item.value}
                                                            checked={item.checked}
                                                            onChange={(event) => changeSearchFilterHandler(event, "vendorName")}
                                                        />
                                                    }
                                                />
                                            )
                                        }
                                    })
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            }
        </div>
    )
}
