import AchieveGoalsIcon from 'assets/svg/dynamic-dashboard/achieve-goals-icon.svg';
import AnniversariesIcon from 'assets/svg/dynamic-dashboard/anniversaries-icon.svg';
import AnnounceIcon from 'assets/svg/dynamic-dashboard/announce-icon.svg';
import BackbarIcon from 'assets/svg/dynamic-dashboard/backbar-icon.svg';
import BarllenoIcon from 'assets/svg/dynamic-dashboard/barlleno-icon.svg';
import BirthdaysIcon from 'assets/svg/dynamic-dashboard/birthdays-icon.svg';
import BrandSalesIcon from 'assets/svg/dynamic-dashboard/brand-sales-icon.svg';
import ChannelsComparisonIcon from 'assets/svg/dynamic-dashboard/channels-comparison-icon.svg';
import DedicatoryIcon from 'assets/svg/dynamic-dashboard/dedicatory-icon.svg';
import NewIncomeIcon from 'assets/svg/dynamic-dashboard/new-income-icon.svg';
import OhbiIcon from 'assets/svg/dynamic-dashboard/ohbi-icon.svg';
import PremierToolsIcon from 'assets/svg/dynamic-dashboard/premier-tools-icon.svg';
import SplitIcon from 'assets/svg/dynamic-dashboard/split-icon.svg';
import { IonContent, IonPage, IonSpinner } from "@ionic/react";
import { useAppSelector } from "hooks";
import { useEffect, useState } from "react"
import { getBrandSalesService, getCartelsImagesService, getDashboardCampaignService, getGoalsVendorService } from "services";
import { CircularGraphicElement, TabInterface } from "../interfaces/dynamic-dashboard.interfaces";
import { AchieveGoalsTab, BrandSalesTab, ChannelsComparisonTab, CartelTab, DedicatoryTab } from "../components";
import { DynamicDashboardTabs } from "types";

// arreglo que define las pestañas que estarán visibles
const tabsArray: TabInterface[] = [
    {
        iconRoute: AchieveGoalsIcon,
        key: "achieve-goals",
        title: "Alcance Metas",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightblue",
    },
    {
        iconRoute: ChannelsComparisonIcon,
        key: "channels-comparison",
        title: "Comparativa entre Canales",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightblue",
    },
    {
        iconRoute: BrandSalesIcon,
        key: "brand-sales",
        title: "Ventas por Marca",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightblue",
    },
    {
        iconRoute: NewIncomeIcon,
        key: "new-income",
        title: "Nuevos Ingresos",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightgreen",
    },
    {
        iconRoute: AnniversariesIcon,
        key: "anniversaries",
        title: "Aniversarios",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightgreen",
    },
    {
        iconRoute: BirthdaysIcon,
        key: "birthdays",
        title: "Cumpleañeros",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightgreen",
    },
    {
        iconRoute: DedicatoryIcon,
        key: "dedicatory",
        title: "Dedicatoria",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-lightgreen"
    },
    {
        iconRoute: AnnounceIcon,
        key: "announces",
        title: "Anuncios",
        visible: true,
        backgroundColor: "bg-dynamic-dashboard-salmon"
    },
]

export const DashboardDynamicPage = () => {
    const { uid } = useAppSelector(state => state.user);

    // estados para manejar la carga de la información
    const [loadingInfo, setLoadingInfo] = useState<boolean>(true);
    const [errorGettingInfo, setErrorGettingInfo] = useState<boolean>(false);
    const [errorGettingUid, setErrorGettingUid] = useState<boolean>(false);

    // arreglo que almacena las pestañas que se mostrarán en pantalla
    const [tabs, setTabs] = useState<any[]>(tabsArray);

    // estado que almacena la cantidad de pestañas que se muestran en pantalla
    const [totalTabs, setTotalTabs] = useState<number>(tabsArray.length);

    // estado que determina la pestaña que será visible en un momento determinado
    const [visibleTab, setVisibleTab] = useState<number>(1);

    // estado para guardar los goalsVendor recuperadas del servicio
    const [goalsVendor, setGoalsVendor] = useState<any>(null);

    // arreglo que almacena el top de ventas completo de los canales
    const [topSales, setTopSales] = useState<CircularGraphicElement[]>([]);

    // arreglo que almacena el top 3 de ventas de canales
    const [top3Sales, setTop3Sales] = useState<CircularGraphicElement[]>([]);

    // arreglo que almacena los datos de las ventas realizadas por cada canal
    // estos valores se usan en las pestañas "Alcance Metas" y "Comparativa entre Canales"
    const [channels, setChannels] = useState<CircularGraphicElement[]>([]);

    // arreglo que almacena los datos de las ventas realizadas por marca
    // estos valores se usan en la pestaña "Ventas por Marca"
    const [brandSales, setBrandSales] = useState<CircularGraphicElement[]>([]);

    // objeto que almacena las imágenes de los carteles
    const [cartelsImages, setCartelsImages] = useState<any>({});

    // propiedad que almacena los datos de la pestaña "dedicatory"
    const [dedicatoryInfo, setDedicatoryInfo] = useState<any[]>([]);

    // propiedad que almacena los datos de la pestaña "announces"
    const [announcesInfo, setAnnouncesInfo] = useState<any[]>([]);

    useEffect(() => {
        const myInterval = setInterval(() => {
            setVisibleTab((prevTime) => {
                let number = Number(prevTime)
                if (number < totalTabs) {
                    number++;
                } else {
                    number = 1
                }
                return number
            });
        }, 1000 * 45);

        return () => {
            clearInterval(myInterval);
        }
    }, [totalTabs]);

    useEffect(() => {
        getCampaignData();
    }, []);

    // Función para obtener el mes y año de la campaña actual
    const getCampaignData = async () => {
        try {
            setLoadingInfo(true);
            setErrorGettingInfo(false);
            setErrorGettingUid(false);

            if (uid) {
                const campaignData = await getDashboardCampaignService(uid);

                if (campaignData && campaignData.mounth && campaignData.year) {
                    // invocamos a la función que recupera los datos de la campaña
                    await getGoalsData(uid, campaignData.mounth, campaignData.year);

                    // invocamos a la función que recupera los datos de las ventas de las marcas
                    await getBrandSalesReport(uid, campaignData.mounth, campaignData.year);

                    // invocamos a la función que recupera las imágenes de las pestañas "Nuevos ingresos",
                    // "Aniversarios" y "Cumpleañeros"
                    await getCartelsImages(uid);

                    setLoadingInfo(false);
                } else {
                    throw new Error("error-getting-campaign");
                }
            } else {
                throw new Error("uid-not-found");
            }
        } catch (error: any) {
            if (error.message === "uid-not-found") {
                setErrorGettingUid(true);
            } else {
                setErrorGettingInfo(true);
            }

            setLoadingInfo(false);
        }
    }

    // Función para obtener el porcentaje de cumplimiento de cada canal
    const getGoalsData = async (uid: string, month: number, year: number) => {
        const goalsVendorResp = await getGoalsVendorService(uid, year, month, true, true);

        if (goalsVendorResp) {
            // Obtenemos el porcentaje de cumplimiento global de todos los canales
            // setCompliancePercentage(goalsVendorResp.details?.percentage ?? 0);

            // Obtenemos el porcentaje de cumplimiento de cada canal
            let channelsArray: CircularGraphicElement[] = [];

            goalsVendorResp.sold?.forEach((channel: any) => {
                const newChannel: CircularGraphicElement = {
                    name: channel.vendor,
                    value: channel.cumplimiento?.toFixed(2),
                    quantity: channel.sold,
                };

                channelsArray.push(newChannel);
            });

            setChannels(channelsArray);

            let channelsArrayAux: any[] = [...channelsArray];

            // Guardamos el top de ventas 
            let topSalesArray: CircularGraphicElement[] = channelsArrayAux.sort((a, b) => b.value - a.value);
            setTopSales(topSalesArray);

            let top3SalesArray = topSalesArray.slice(0, 3);
            setTop3Sales(top3SalesArray);

            // Guardamos el reporte de ventas completo
            setGoalsVendor(goalsVendorResp);
        } else {
            throw new Error("error-getting-goals-vendor");
        }
    }

    // Función para obtener los valores de las ventas de las marcas
    const getBrandSalesReport = async (uid: string, month: number, year: number) => {
        const brandSalesResp = await getBrandSalesService(uid, year, month, true, true);

        if (brandSalesResp) {
            // Del total de marcas solo tomamos las primeras 10
            const brandSalesAux = brandSalesResp.slice(0, 10);

            const brandSalesArray: CircularGraphicElement[] = brandSalesAux.map((item: any) => {
                return {
                    name: item.marca,
                    value: item.sold,
                    quantity: item.sold,
                }
            });

            setBrandSales(brandSalesArray);
        } else {
            throw new Error("error-getting-brand-sales-report");
        }
    }

    // Función para obtener las imágenes que se muestran en las pestañas de "Nuevos ingresos",
    // "Aniversarios" y "Cumpleañeros"
    const getCartelsImages = async (uid: string) => {
        const cartelsImagesResp = await getCartelsImagesService(uid);

        if (cartelsImagesResp) {
            let cartelsImagesObject = {
                [cartelsImagesResp.images[0].type]: cartelsImagesResp.images[0].image,
                [cartelsImagesResp.images[1].type]: cartelsImagesResp.images[1].image,
                [cartelsImagesResp.images[2].type]: cartelsImagesResp.images[2].image,
            }

            setCartelsImages(cartelsImagesObject);

            let copyTabsArray = [...tabs];

            if (cartelsImagesResp.birthdays.length > 0) {
                // Verificamos si se ha creado alguna dedicatoria especial en la campaña actual
                let dedicatoryInfoAux = cartelsImagesResp.birthdays.filter((item: any) => item.type !== "message");

                if (dedicatoryInfoAux) {
                    setDedicatoryInfo(dedicatoryInfoAux);
                } else {
                    hideTabVisibility("dedicatory", copyTabsArray);
                }

                // Verificamos si se ha creado algún aviso en la campaña actual
                let announceInfo = cartelsImagesResp.birthdays.filter((item: any) => item.type === "message");

                if (announceInfo) {
                    setAnnouncesInfo(announceInfo);
                } else {
                    hideTabVisibility("announces", copyTabsArray);
                }
            } else {
                // si no hay dedicatorias ni mensajes ocultamos sus tabs correspondientes
                hideTabVisibility("dedicatory", copyTabsArray);;
                hideTabVisibility("announces", copyTabsArray);
            }

            // guardamos solo las tabs cuya propiedad "visible" sea true
            copyTabsArray = copyTabsArray.filter((item: TabInterface) => item.visible);

            setTotalTabs(copyTabsArray.length);
            setTabs(copyTabsArray);
        } else {
            throw new Error("error-getting-cartels-images");
        }
    }

    const hideTabVisibility = (tabKey: DynamicDashboardTabs, tabsArray: TabInterface[]) => {
        let tabIndex = tabs.findIndex((item: any) => item.key === tabKey);

        if (tabIndex !== -1) {
            let foundTab = tabsArray[tabIndex];

            foundTab = { ...foundTab, visible: false };

            tabsArray[tabIndex] = foundTab;
        }
    }

    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-screen flex flex-col items-center">
                    {
                        loadingInfo &&
                        <div className="w-full h-screen flex flex-col justify-center items-center col-span-10">
                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                        </div>
                    }

                    {
                        !loadingInfo &&
                        <div id="main-section" className={`w-full h-screen flex flex-col items-center gap-4 bg-light overflow-y-scroll py-4`}>
                            <div className={`${tabs.length <= 6 ? "w-4/5 max-w-[1536px]" : 'w-[85%] grid-cols-' + tabs.length} pb-5 border-b border-tertiary grid gap-4`}>
                                {
                                    tabs.map((item: TabInterface, index: number) => (
                                        <div
                                            key={item.title}
                                            onClick={() => setVisibleTab(index + 1)}
                                            className={`p-2 bg-white border border-tertiary grid grid-cols-6 items-center gap-2 rounded-md text-mid font-semibold cursor-pointer ${(index + 1) === visibleTab && 'logo-card'}`}
                                        >
                                            <div className={`${item.backgroundColor} p-2 rounded-md col-span-2 flex justify-center items-center`}>
                                                <img src={(item.iconRoute)} alt={`Ícono de ${item.title}`} />
                                            </div>

                                            <p>{item.title}</p>
                                        </div>
                                    ))
                                }
                            </div>

                            <div className="w-4/5 max-w-[1536px] flex flex-col gap-5">
                                <div className="grid grid-cols-8 gap-5">
                                    {/* Sección fija que estará en todas las pestañas */}
                                    <div className="col-span-2 flex flex-col gap-4">
                                        <div className="bg-white rounded-md logo-card flex flex-col gap-4 p-4">
                                            <p className="font-bold">Top de Ventas</p>

                                            <div className="flex flex-col gap-2">
                                                {
                                                    top3Sales.map((item: CircularGraphicElement) => (
                                                        <div key={item.name} className="pb-2 border-b border-tertiary last:border-b-0 last:pb-10">
                                                            <p className="uppercase text-mid">{item.name}</p>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-5 gap-2">
                                            <div className="w-full flex justify-center">
                                                <img src={BarllenoIcon} alt="Logo de Barlleno" className="h-[35px] object-contain" />
                                            </div>

                                            <div className="w-full flex justify-center">
                                                <img src={SplitIcon} alt="Logo de Split US" className="h-[35px] object-contain" />
                                            </div>

                                            <div className="w-full flex justify-center">
                                                <img src={BackbarIcon} alt="Logo de Backbar" className="h-[35px]" />
                                            </div>

                                            <div className="w-full flex justify-center">
                                                <img src={OhbiIcon} alt="Logo de OHBI" className="h-[35px] object-contain" />
                                            </div>

                                            <div className="w-full flex justify-center">
                                                <img src={PremierToolsIcon} alt="Logo de PremierTools" className="h-[35px] object-contain" />
                                            </div>
                                        </div>
                                    </div>

                                    {visibleTab === 1 && <AchieveGoalsTab goalsVendor={goalsVendor} channels={channels} />}
                                    {visibleTab === 2 && <ChannelsComparisonTab channels={topSales} />}
                                    {visibleTab === 3 && <BrandSalesTab brandSales={brandSales} />}
                                    {visibleTab === 4 && <CartelTab cartel={cartelsImages.new} alt="Nuevos ingresos" />}
                                    {visibleTab === 5 && <CartelTab cartel={cartelsImages.aniversary} alt="Aniversarios" />}
                                    {visibleTab === 6 && <CartelTab cartel={cartelsImages.birthday} alt="Cumpleaños" />}
                                    {dedicatoryInfo.length > 0 && visibleTab === 7 && <DedicatoryTab data={dedicatoryInfo} />}
                                    {announcesInfo.length > 0 && announcesInfo[0].image && announcesInfo[0].image.length > 0 && visibleTab === 8 && <CartelTab cartel={announcesInfo[0].image[0]} alt="Imagen del anuncio del día" />}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </IonContent>
        </IonPage>
    )
}