export const IconSellersControl = () => {
    return (
        <svg width="128" height="178" viewBox="0 0 128 178" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_254_51)">
                <path d="M102.889 22.8655H115.268C121.896 22.8655 127.269 28.2381 127.269 34.8655V164.708C127.269 171.336 121.896 176.708 115.269 176.708H12.7314C6.10403 176.708 0.731445 171.336 0.731445 164.708V34.8655C0.731445 28.2381 6.10403 22.8655 12.7314 22.8655H25.2796" stroke="#183555" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M47.8164 14.372C47.8164 7.03638 55.0653 1.08582 64.0038 1.08582C72.9424 1.08582 80.1913 7.03638 80.1913 14.372L95.3839 14.3866C99.4436 14.3866 102.728 17.6844 102.728 21.7442V33.8432H25.2798V21.7442C25.2798 17.677 28.5714 14.3866 32.6237 14.3866L47.8164 14.372Z" stroke="#183555" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M26.1382 152.466L55.8786 122.521L70.5694 137.306L97.6475 110.035" stroke="#183555" strokeWidth="8" strokeMiterlimit="10" strokeLinecap="round" />
                <path d="M82.3911 105.799H101.862V125.403" stroke="#183555" strokeWidth="8" strokeMiterlimit="10" />
                <g clipPath="url(#clip1_254_51)">
                    <path d="M84.8563 72.5118L80.4976 70.9585C80.0733 69.1384 79.3548 67.4277 78.3969 65.8881L80.3539 61.7689C79.3754 60.5509 78.26 59.4492 77.0352 58.4776L72.9365 60.4277C71.39 59.4698 69.6862 58.7513 67.8661 58.327L66.347 54.0778C65.5738 53.9888 64.7937 53.9409 64 53.9409C63.2063 53.9409 62.4262 53.9888 61.653 54.0778L60.1408 58.3339C58.3206 58.7581 56.61 59.4766 55.0704 60.4346L50.9717 58.4844C49.7468 59.4561 48.6315 60.5577 47.653 61.7757L49.61 65.895C48.652 67.4414 47.9335 69.1452 47.5093 70.9654L43.1437 72.5118C43.0547 73.3055 43 74.1198 43 74.9409C43 75.6799 43.0411 76.4052 43.1163 77.1237L47.5093 78.6838C47.9335 80.504 48.652 82.2146 49.61 83.7542L47.5846 88.0172C48.5357 89.2078 49.61 90.2889 50.7869 91.2537L55.0704 89.2215C56.6168 90.1794 58.3206 90.8979 60.1408 91.3222L61.7419 95.8178C62.4878 95.8999 63.2405 95.9409 64.0068 95.9409C64.7732 95.9409 65.5259 95.8999 66.2717 95.8178L67.8729 91.3222C69.6931 90.8979 71.4037 90.1794 72.9433 89.2215L77.2268 91.2537C78.4106 90.2958 79.4848 89.2078 80.4291 88.0172L78.4037 83.7542C79.3617 82.2078 80.0802 80.504 80.5044 78.6838L84.8974 77.1237C84.9726 76.4052 85.0137 75.6799 85.0137 74.9409C85.0137 74.1198 84.9589 73.3124 84.87 72.5118H84.8563ZM64 82.7347C59.696 82.7347 56.2063 79.2449 56.2063 74.9409C56.2063 70.6369 59.696 67.1472 64 67.1472C68.304 67.1472 71.7937 70.6369 71.7937 74.9409C71.7937 79.2449 68.304 82.7347 64 82.7347Z" fill="#183555" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_254_51">
                    <rect width="128" height="177" fill="white" transform="translate(0 0.440918)" />
                </clipPath>
                <clipPath id="clip1_254_51">
                    <rect width="42" height="42" fill="white" transform="translate(43 53.9409)" />
                </clipPath>
            </defs>
        </svg>

    )
}