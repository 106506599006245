import { IonSpinner } from "@ionic/react"

export const LoadingModal = ({ whiteBackground, text }: { whiteBackground: boolean, text?: string }) => {
    return (
        <div className='w-screen h-screen fixed flex justify-center items-center z-30'>
            <div className={`${whiteBackground ? 'bg-white' : ''} w-[30%] flex flex-col items-center px-4 py-8 rounded-md`}>
                <div className={`${whiteBackground ? 'bg-white' : ''}`}>
                    <IonSpinner name="crescent" className="text-primary w-[50px] h-[50px]" />
                </div>

                {
                    text !== undefined &&
                    <div className='w-full mt-2 py-2 px-4'>
                        <p className='text-center font-semibold'>{text}</p>
                    </div>
                }
            </div>
        </div>
    )
}