import EmptyFolder from 'assets/img/app/empty-folder.png';
import { IonContent, IonDatetime, IonIcon, IonPage, IonSpinner } from "@ionic/react"
import { SidebarMenu, SolidButton } from "components"
import { orderBy, where } from "firebase/firestore";
import { useEffect, useRef, useState } from "react"
import { convertTimestampToDate, formatDateToISODateString, formatDateToLocaleDateString, formateDateStringToDate, formatISODateStringToDate, getDifferenceInDays, getDocumentById, getDocumentsByFilterArray, getSearchFilters, getSearchFiltersValues } from "utils";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Pagination } from '@mui/material';
import { caretDown, star } from 'ionicons/icons';
import { SearchFilterItem, SearchFilters } from 'interfaces';

interface SucursalData {
    data: any,
    quantity: number,
}

interface DisplayData {
    sucursales: Record<string, SucursalData>,
    quantity: number,
}

interface InventoryData {
    displays: Record<string, DisplayData>,
    quantity: number,
}

type InventoryFilters = "displayName" | "sucursal";

const initialSearchFilters: Record<InventoryFilters, SearchFilters> = {
    displayName: {
        allChecked: true,
        allHidden: false,
        items: [],
    },
    sucursal: {
        allChecked: true,
        allHidden: false,
        items: []
    },
}

export const InventoryPage = () => {
    const today = new Date();
    const todayAux = new Date();
    const todayAuxPagination = new Date();

    // estado para almacenar los benchmarks recuperados
    const [benchmarks, setBenchmarks] = useState<any[]>([]);
    const [filteredBenchmarks, setFilteredBenchmarks] = useState<any[]>([]);

    // estado para manejar la pantalla de error
    const [errorGettingReportData, setErrorGettingReportData] = useState<boolean>(false);

    // estados para almacenar los datos del reporte
    const [report, setReport] = useState<Record<string, InventoryData>[] | null>(null);
    const [visibleReport, setVisibleReport] = useState<Record<string, InventoryData> | null>(null);

    // estados para manejar las pantallas de carga
    const [searchingReportData, setSearchingReportData] = useState<boolean>(true);
    const [filteringReport, setFilteringReport] = useState<boolean>(false);

    // estados para manejar la paginación
    const [paginationCount, setPaginationCount] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [recordsPerView] = useState<number>(5);

    // estado que almacena el inicio del rango de fechas de los benchmarks consultadas
    const [startDate, setStartDate] = useState<Date>(new Date(todayAux.setDate(todayAux.getDate() - 15)));

    // estado que almacena el fin del rango de fechas de los benchmarks consultadas
    const [finishDate, setFinishDate] = useState<Date>(new Date());

    // estado que almacena la fecha de inicio escogida en el calendario de los filtros de búsqueda
    const [startDateSearchFilter, setStartDateSearchFilter] = useState<Date>(new Date(startDate));

    // estado que almacena la fecha de fin escogida en el calendario de los filtros de búsqueda
    const [finishDateSearchFilter, setFinishDateSearchFilter] = useState<Date>(new Date());

    const [startDatePagination, setStartDatePagination] = useState<Date>(new Date(todayAuxPagination.setDate(todayAuxPagination.getDate() - 5)));

    const [finishDatePagination, setFinishDatePagination] = useState<Date>(new Date());

    // estado para saber si el rango de fecha de consulta de los benchmarks ha cambiado
    // para asegurar que el estado sea único se almacenará la fecha y la hora en la que se modifica dicho rango
    const [hasDatesChanged, setHasDatesChanged] = useState<number>(1);

    // estados para almacenar los valores que aparecerán en los filtros de búsqueda
    const [searchFilters, setSearchFilters] = useState<Record<InventoryFilters, SearchFilters>>(initialSearchFilters)

    const contentRef = useRef<HTMLIonContentElement>(null);

    useEffect(() => {
        getReportData();
    }, [hasDatesChanged]);

    // Función para recuperar la información a presentar en el reporte
    const getReportData = async () => {
        try {
            setSearchingReportData(true);

            let filters = [
                where("test", "==", false),
                where("created_at", ">=", startDate),
                where("created_at", "<=", finishDate),
                orderBy("created_at", "desc")
            ]

            const reportSnapshot = await getDocumentsByFilterArray("benchmarks", filters);

            if (reportSnapshot.status === 200 && reportSnapshot.data) {
                const datesDifferenceInDays = getDifferenceInDays(startDate, finishDate);

                setPaginationCount(Math.ceil(datesDifferenceInDays / recordsPerView));
               
                // mapeamos la información de los benchmarks
                let benchmarksArray: any[] = await Promise.all(
                    reportSnapshot.data.map(async (item: any) => {
                        // definimos una propiedad para saber si el apartado de INVENTARIO
                        // ya fue terminado en este benchmark
                        let finished = false;

                        if (item.hasOwnProperty("statusDetails")) {
                            const isFinished = item.statusDetails.find((status: any) => status.type === "inventory" && status.finished === true);

                            if (isFinished) {
                                finished = true;
                            }
                        }

                        const displaySnapshot = await getDocumentById("accounts", item.userId);
                        let displayName: string = "";

                        if (displaySnapshot.status === 200 && displaySnapshot.data) {
                            displayName = displaySnapshot.data.name ?? "";
                        }

                        const created: string = convertTimestampToDate(item.created_at) ?? "";

                        let benchmarkRecord: any = {
                            $key: item.$key,
                            displayName,
                            sucursal: item.nameSucursal,
                            created,
                            finished
                        }

                        if (item.hasOwnProperty("productsInventory") && item.productsInventory.length > 0) {
                            benchmarkRecord = {
                                ...benchmarkRecord,
                                products: item.productsInventory,
                            }
                        }

                        if (item.hasOwnProperty("notes")) {
                            let noteInventory = item.notes.find((note: any) => note.section === "inventory");

                            if (noteInventory) {
                                benchmarkRecord = {
                                    ...benchmarkRecord,
                                    noteInventory: noteInventory.notes,
                                }
                            }
                        }

                        return benchmarkRecord;
                    })
                );

                benchmarksArray = benchmarksArray.filter((item: any) => item.finished);

                // Obtenemos los valores de los filtros de búsqueda
                const displaysArray = getSearchFilters(benchmarksArray, "displayName");

                const sucursalsArray = getSearchFilters(benchmarksArray, "sucursal");

                setSearchFilters({
                    displayName: {
                        allChecked: true,
                        allHidden: false,
                        items: displaysArray,
                    },
                    sucursal: {
                        allChecked: true,
                        allHidden: false,
                        items: sucursalsArray,
                    },
                });


                // guardamos el reporte completo de benchmarks
                setBenchmarks(benchmarksArray);
                setFilteredBenchmarks(benchmarksArray);

                let reportAux: Record<string, InventoryData>[] = [];

                // Obtenemos los registros de las primeras 10 fechas
                const actualDateAux = new Date(finishDate);
                const tenDaysAgo = new Date(actualDateAux.setDate(actualDateAux.getDate() - recordsPerView));

                actualDateAux.setHours(0, 0, 0, 0);
                tenDaysAgo.setHours(0, 0, 0, 0);

                const filteredBenchmarkAux = filterBenchmarksByDateRange(benchmarksArray, tenDaysAgo, finishDate);

                let finalData = groupByDateAndVendorWithCount(filteredBenchmarkAux);

                reportAux.push(finalData);

                if (finalData) {
                    setReport(reportAux);
                    setVisibleReport(reportAux[currentPage - 1]);
                } else {
                    throw new Error("error-mapping-inventory");
                }
            } else {
                throw new Error("error-getting-benchmark");
            }
        } catch (error: any) {
            setErrorGettingReportData(true);
            setBenchmarks([]);
            setFilteredBenchmarks([]);
            setReport(null);
            setVisibleReport(null);
        } finally {
            setSearchingReportData(false);
        }
    }

    // Función que obtiene solo los registros de los benchmarks que coinciden con un rango
    // de fechas que se le envía por parámetro
    const filterBenchmarksByDateRange = (benchmarkArray: any[], startDate: Date, finishDate: Date) => {
        // Filtramos los registros que están dentro del rango
        return benchmarkArray.filter((item: any) => {
            const recordDate = formateDateStringToDate(item.created, false);

            if (recordDate) {
                return recordDate >= startDate && recordDate <= finishDate;
            }

            return false;
        })
    }

    // Función que itera el arreglo de benchmarks y agrupa los datos por fecha
    // y luego por vendedores
    const groupByDateAndVendorWithCount = (data: any[]) => {
        return data.reduce((acc, item) => {
            try {
                const { created, displayName, products = [], sucursal } = item;

                // Calcular el conteo de productos del item actual
                const productCount = products.reduce((sum: number, product: any) => sum + product.quantity, 0);

                // Si la fecha no existe, la creamos
                if (!acc[created]) {
                    acc[created] = { displays: {}, quantity: 0 };
                }

                // Si el vendedor no existe en esa fecha, lo creamos
                if (!acc[created].displays[displayName]) {
                    acc[created].displays[displayName] = { sucursales: [], quantity: 0 };
                }

                // Si la sucursal no existe bajo ese vendedor, la creamos
                if (!acc[created].displays[displayName].sucursales[sucursal]) {
                    acc[created].displays[displayName].sucursales[sucursal] = { data: {}, quantity: 0 };
                }

                // Añadir el item bajo la fecha y el vendedor correctos
                acc[created].displays[displayName].sucursales[sucursal].data = item;
                acc[created].displays[displayName].sucursales[sucursal].quantity += productCount;
                acc[created].displays[displayName].quantity += productCount;
                acc[created].quantity += productCount;

                return acc;
            } catch (error: any) {
                console.log(error);
                return null;
            }
        }, {} as Record<
            string,
            {
                displays: Record<string, { items: any[]; quantity: number }>;
                quantity: number;
            }
        >);
    };

    const changeStartDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newStartDate = formatISODateStringToDate(stringDate, false);

            if (newStartDate) {
                const diferenciaMilisegundos = Number(finishDateSearchFilter) - Number(newStartDate);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 15) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newStartDate);

                    const newFinishDate = new Date(dateAux.setDate(dateAux.getDate() + 15));

                    if (Number(newFinishDate) > Number(today)) {
                        setFinishDateSearchFilter(today);
                    } else {
                        setFinishDateSearchFilter(newFinishDate)
                    }
                }

                setStartDateSearchFilter(newStartDate);
            }
        }
    }

    const changeFinishDateHandler = (stringDate?: string | string[] | null) => {
        if (stringDate && !Array.isArray(stringDate)) {
            const newFinishDate = formatISODateStringToDate(stringDate, true);

            if (newFinishDate) {
                const diferenciaMilisegundos = Number(newFinishDate) - Number(startDateSearchFilter);

                if
                    (
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) > 15) ||
                    (Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24)) < 0)
                ) {
                    const dateAux = new Date(newFinishDate);

                    const newStartDate = new Date(dateAux.setDate(dateAux.getDate() - 15));

                    setStartDateSearchFilter(newStartDate);
                }

                setFinishDateSearchFilter(newFinishDate);
            }
        }
    }

    // Función que se ejecuta cuando el usuario aplica el filtro de fechas
    const applyDateFilter = () => {
        if ((startDate !== startDateSearchFilter) || (finishDate !== finishDateSearchFilter)) {
            setStartDate(startDateSearchFilter);
            setFinishDate(finishDateSearchFilter);

            const finishDateSearchFilterAux = new Date(finishDateSearchFilter);
            const newStartDatePagination = new Date(finishDateSearchFilterAux.setDate(finishDateSearchFilterAux.getDate() - 5));

            setStartDatePagination(newStartDatePagination);
            setFinishDatePagination(finishDateSearchFilter);
            setCurrentPage(1);
            setHasDatesChanged(hasDatesChanged + 1);
        }
    }

    // Función que se ejecuta cuando el usuario interactua con la paginación
    const changePaginationHandler = async (event: React.ChangeEvent<unknown>, value: number) => {
        setFilteringReport(true);

        if (report) {
            let visibleReportAux: Record<string, InventoryData> | null = null;

            console.log(startDatePagination);

            console.log(finishDatePagination);
            
            if (report[value - 1] === undefined) {
                // Obtenemos los registros de las primeras 10 fechas
                const startDatePaginationAux = new Date(startDatePagination);
                const startDateAux = new Date(startDatePaginationAux.setDate(startDatePaginationAux.getDate() - ((value - 1) * recordsPerView) - (value - 1)));
                startDateAux.setHours(0, 0, 0, 0);

                const finishDatePaginationAux = new Date(finishDatePagination);
                const finishDateAux = new Date(finishDatePaginationAux.setDate(finishDatePaginationAux.getDate() - ((value - 1) * recordsPerView) - (value - 1)));
                finishDateAux.setHours(0, 0, 0, 0);

                console.log(startDateAux);

                console.log(finishDateAux);

                const filteredBenchmarkAux = filterBenchmarksByDateRange(filteredBenchmarks, startDateAux, finishDateAux);

                visibleReportAux = groupByDateAndVendorWithCount(filteredBenchmarkAux);

                let reportAux = report;

                if (visibleReportAux) {
                    reportAux[value - 1] = visibleReportAux;
                }

                setReport(reportAux);
            } else {
                visibleReportAux = report[value - 1];
            }

            setVisibleReport(visibleReportAux);
            setCurrentPage(value);

            if (contentRef && contentRef.current) {
                contentRef.current.scrollToTop(500);
            }
        }

        setFilteringReport(false);
    }

    const allCheckedChangeHandler = (filterName: InventoryFilters) => {
        const filterCategory = searchFilters[filterName];
        const newCheckedState = !filterCategory.allChecked;

        const updatedItems = filterCategory.items.map(item => {
            if (item.visible) {
                return { ...item, checked: newCheckedState };
            }
            return item;
        });

        const searchFiltersAux = {
            ...searchFilters,
            [filterName]: {
                ...searchFilters[filterName],
                allChecked: newCheckedState,
                items: updatedItems
            }
        };

        updateFiltersAndBenchmarks(searchFiltersAux, filterName);
    };

    const changeSearchFilterHandler = (event: React.ChangeEvent<HTMLInputElement>, filterName: InventoryFilters) => {
        const filterCategory = searchFilters[filterName];
        const filterArray = filterCategory.items;

        const indexFilter = filterArray.findIndex(item => item.value === event.target.value);

        if (indexFilter !== -1) {
            const updatedFilter = { ...filterArray[indexFilter], checked: event.target.checked };

            const updatedFilterArray = [
                ...filterArray.slice(0, indexFilter),
                updatedFilter,
                ...filterArray.slice(indexFilter + 1)
            ];

            const searchFiltersAux = {
                ...searchFilters,
                [filterName]: {
                    ...searchFilters[filterName],
                    items: updatedFilterArray
                }
            };

            updateFiltersAndBenchmarks(searchFiltersAux, filterName);
        }
    };

    const updateFiltersAndBenchmarks = (searchFiltersAux: Record<InventoryFilters, SearchFilters>, filterName: InventoryFilters) => {
        const filterCategory = searchFiltersAux[filterName];
        const checkedFilters = filterCategory.items.filter(item => item.checked).map(item => item.value);
        let newFilteredBenchmark = benchmarks.filter(item => checkedFilters.includes(item[filterName]));

        for (const key in searchFiltersAux) {
            if (key !== filterName) {
                const foundSearchFilters = getSearchFiltersValues(newFilteredBenchmark, key as InventoryFilters);

                searchFiltersAux[key as InventoryFilters].items = searchFiltersAux[key as InventoryFilters].items.map(item => ({
                    ...item,
                    visible: foundSearchFilters.includes(item.value)
                }));
            }

            const allChecked = searchFiltersAux[key as InventoryFilters].items.every(item => !item.visible || item.checked);
            const allHidden = searchFiltersAux[key as InventoryFilters].items.every(item => !item.visible);

            searchFiltersAux[key as InventoryFilters] = {
                ...searchFiltersAux[key as InventoryFilters],
                allChecked,
                allHidden,
            }
        }

        const activeFilters = Object.keys(searchFiltersAux).reduce((acc, key) => {
            const filters = searchFiltersAux[key as InventoryFilters].items
                .filter(item => item.checked && item.visible)
                .map(item => item.value);
            return { ...acc, [key]: filters };
        }, {} as Record<InventoryFilters, string[]>);

        newFilteredBenchmark = benchmarks.filter(item => {
            return Object.keys(activeFilters).every(key => {
                const filterKey = key as InventoryFilters;
                return activeFilters[filterKey].includes(item[filterKey]);
            });
        });

        setSearchFilters(searchFiltersAux);
        setFilteredBenchmarks(newFilteredBenchmark);
        setCurrentPage(1);
    };

    useEffect(() => {
        changeFilteredBenchmarkHandler();
    }, [filteredBenchmarks]);

    const changeFilteredBenchmarkHandler = async () => {
        let reportAux: Record<string, InventoryData>[] = [];

        // Obtenemos los registros de las primeras 10 fechas
        const actualDateAux = new Date(finishDate);
        const tenDaysAgo = new Date(actualDateAux.setDate(actualDateAux.getDate() - recordsPerView));

        actualDateAux.setHours(0, 0, 0, 0);
        tenDaysAgo.setHours(0, 0, 0, 0);

        const filteredBenchmarkAux = filterBenchmarksByDateRange(filteredBenchmarks, tenDaysAgo, finishDate);

        let finalData = groupByDateAndVendorWithCount(filteredBenchmarkAux);

        reportAux.push(finalData);

        setReport(reportAux);
        setVisibleReport(reportAux[currentPage - 1]);
        setFilteringReport(false);
    }

    return (
        <IonPage>
            <IonContent ref={contentRef}>
                <div className="w-full h-screen grid grid-cols-12">
                    {/* Menú lateral */}
                    <div className="col-span-2 border-r-2 border-solid border-light">
                        <SidebarMenu pageName="inventory" />
                    </div>

                    <div className="w-full h-screen flex flex-col flex-grow col-span-10">
                        <div className="w-full flex justify-between border-b-2 border-tertiary px-4 py-3">
                            <p className="text-primary font-bold text-lg">Inventario</p>

                            <p className="font-semibold text-lg">Del {formatDateToLocaleDateString(startDate)} al {formatDateToLocaleDateString(finishDate)}</p>
                        </div>

                        <div id="main-section" className="w-full h-screen overflow-y-scroll grid grid-cols-10">
                            {
                                searchingReportData &&
                                <div className="col-span-10 w-full flex flex-col items-center justify-center">
                                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                </div>
                            }

                            {
                                !searchingReportData &&
                                <div className="w-full flex flex-col flex-grow col-span-7">
                                    {
                                        filteringReport &&
                                        <div className="w-full h-screen flex flex-col items-center justify-center">
                                            <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                                        </div>
                                    }

                                    {
                                        !filteringReport && !(visibleReport) &&
                                        <div className="h-full grid grid-cols-8">
                                            <div className="col-span-8 flex flex-col items-center gap-4 p-4">
                                                <img src={EmptyFolder} alt='empty' className="h-[200px] object-contain" />

                                                <p className="text-xl text-center font-semibold text-primary">No se encontraron resultados para tu búsqueda</p>
                                            </div>
                                        </div>
                                    }

                                    {
                                        !filteringReport && visibleReport &&
                                        <div className="w-full h-full flex flex-col">
                                            {
                                                Object.keys(visibleReport).map((created: string) => (
                                                    <Accordion key={created} sx={{ boxShadow: 'none;' }}>
                                                        <AccordionSummary
                                                            sx={{ display: 'flex', flexDirection: 'row-reverse', backgroundColor: '#F4F5F5' }}
                                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <div className="w-full flex justify-between items-center pl-2 text-mid">
                                                                <p>{created}</p>
                                                                <p className="font-bold">{visibleReport[created].quantity}</p>
                                                            </div>
                                                        </AccordionSummary>

                                                        <AccordionDetails>
                                                            {
                                                                Object.keys(visibleReport[created].displays).map((display: string) => (
                                                                    <Accordion key={created + "_" + display} sx={{ boxShadow: 'none;' }}>
                                                                        <AccordionSummary
                                                                            sx={{ display: 'flex', flexDirection: 'row-reverse', backgroundColor: '#F4F5F5' }}
                                                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                                                            aria-controls="panel1a-content"
                                                                            id="panel1a-header"
                                                                        >
                                                                            <div className="w-full flex justify-between items-center pl-2 text-mid">
                                                                                <p>{display}</p>
                                                                                <p className="font-bold">{visibleReport[created].displays[display].quantity}</p>
                                                                            </div>
                                                                        </AccordionSummary>

                                                                        <AccordionDetails>
                                                                            {
                                                                                Object.keys(visibleReport[created].displays[display].sucursales).map((sucursal: string) => (
                                                                                    <Accordion key={created + "_" + display + "_" + sucursal} sx={{ boxShadow: 'none;' }}>
                                                                                        <AccordionSummary
                                                                                            sx={{ display: 'flex', flexDirection: 'row-reverse', backgroundColor: '#F4F5F5' }}
                                                                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                                                                            aria-controls="panel1a-content"
                                                                                            id="panel1a-header"
                                                                                        >
                                                                                            <div className="w-full flex justify-between items-center pl-2 text-mid">
                                                                                                <p>{sucursal}</p>
                                                                                                <p className="font-bold">{visibleReport[created].displays[display].sucursales[sucursal].quantity}</p>
                                                                                            </div>
                                                                                        </AccordionSummary>

                                                                                        <AccordionDetails>
                                                                                            {
                                                                                                visibleReport[created].displays[display].sucursales[sucursal].data.hasOwnProperty("products") &&
                                                                                                visibleReport[created].displays[display].sucursales[sucursal].data.products.map((product: any) => (
                                                                                                    <div
                                                                                                        key={created + "_" + display + "_" + sucursal + "_" + product.id}
                                                                                                        className="flex justify-between border-b-2 last:border-b-0 border-tertiary"
                                                                                                    >
                                                                                                        <p>{product.name}</p>
                                                                                                        <p>{product.quantity}</p>
                                                                                                    </div>
                                                                                                ))
                                                                                            }

                                                                                            {
                                                                                                visibleReport[created].displays[display].sucursales[sucursal].data.hasOwnProperty("noteInventory") &&
                                                                                                <p>{visibleReport[created].displays[display].sucursales[sucursal].data.noteInventory}</p>

                                                                                            }
                                                                                        </AccordionDetails>
                                                                                    </Accordion>
                                                                                ))
                                                                            }
                                                                        </AccordionDetails>
                                                                    </Accordion>
                                                                ))
                                                            }
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))
                                            }
                                        </div>
                                    }

                                    <div className={`w-full flex justify-center mt-auto py-4`}>
                                        <Pagination
                                            size="large"
                                            color="primary"
                                            count={paginationCount}
                                            defaultPage={1}
                                            page={currentPage}
                                            siblingCount={1}
                                            boundaryCount={2}
                                            onChange={changePaginationHandler}
                                        />
                                    </div>
                                </div>
                            }

                            {/* Filtros de búsqueda */}
                            {
                                !searchingReportData &&
                                <div className="w-full overflow-y-scroll flex p-2 flex-col col-span-3 order-last border-l-2 border-light">
                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Fecha</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col items-center gap-12">
                                                <div className="flex flex-col gap-2">
                                                    <p className="font-semibold">Fecha de inicio</p>
                                                    <IonDatetime
                                                        presentation="date"
                                                        value={formatDateToISODateString(startDateSearchFilter)}
                                                        max={formatDateToISODateString(today)}
                                                        onIonChange={(event) => changeStartDateHandler(event.detail.value)}
                                                    />
                                                </div>

                                                <div className="flex flex-col gap-2">
                                                    <p className="font-semibold">Fecha de fin</p>
                                                    <IonDatetime
                                                        presentation="date"
                                                        value={formatDateToISODateString(finishDateSearchFilter)}
                                                        max={formatDateToISODateString(today)}
                                                        onIonChange={(event) => changeFinishDateHandler(event.detail.value)}
                                                    />
                                                </div>

                                                <div className="w-full xl:w-1/2">
                                                    <SolidButton
                                                        text="Aplicar"
                                                        backgroundColor="primary"
                                                        disabled={false}
                                                        onClickHandler={applyDateFilter}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Displays</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col">
                                                {
                                                    searchFilters.displayName.allHidden &&
                                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                                }

                                                {
                                                    !searchFilters.displayName.allHidden &&
                                                    <FormControlLabel
                                                        label={"Todos"}
                                                        control=
                                                        {
                                                            <Checkbox
                                                                id={"displays_all"}
                                                                name={"displays_all"}
                                                                checked={searchFilters.displayName.allChecked}
                                                                onChange={() => allCheckedChangeHandler("displayName")}
                                                            />
                                                        }
                                                    />
                                                }

                                                {
                                                    !searchFilters.displayName.allHidden && searchFilters.displayName.items.map((item: SearchFilterItem) => {
                                                        if (item.visible) {
                                                            return (
                                                                <FormControlLabel
                                                                    key={item.value}
                                                                    label={item.label}
                                                                    control=
                                                                    {
                                                                        <Checkbox
                                                                            id={item.value}
                                                                            name={item.value}
                                                                            value={item.value}
                                                                            checked={item.checked}
                                                                            onChange={(event) => changeSearchFilterHandler(event, "displayName")}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    })
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>

                                    <Accordion className="accordion-search-filter">
                                        <AccordionSummary
                                            expandIcon={<IonIcon icon={caretDown} className="text-[18px] text-black" />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <p className="font-semibold text-mid">Sucursales</p>
                                        </AccordionSummary>

                                        <AccordionDetails>
                                            <div className="flex flex-col">
                                                {
                                                    searchFilters.sucursal.allHidden &&
                                                    <p className="font-semibold text-primary">No hay datos disponibles</p>
                                                }

                                                {
                                                    !searchFilters.sucursal.allHidden &&
                                                    <FormControlLabel
                                                        label={"Todas"}
                                                        control=
                                                        {
                                                            <Checkbox
                                                                id={"sucursals_all"}
                                                                name={"sucursals_all"}
                                                                checked={searchFilters.sucursal.allChecked}
                                                                onChange={() => allCheckedChangeHandler("sucursal")}
                                                            />
                                                        }
                                                    />
                                                }

                                                {
                                                    !searchFilters.sucursal.allHidden && searchFilters.sucursal.items.map((item: SearchFilterItem) => {
                                                        if (item.visible) {
                                                            return (
                                                                <FormControlLabel
                                                                    key={item.value}
                                                                    label={item.value}
                                                                    control=
                                                                    {
                                                                        <Checkbox
                                                                            id={item.value}
                                                                            name={item.value}
                                                                            value={item.value}
                                                                            checked={item.checked}
                                                                            onChange={(event) => changeSearchFilterHandler(event, "sucursal")}
                                                                        />
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    }

                                                        // <IonCheckbox key={item.value} mode="md" checked={item.checked} labelPlacement="end">
                                                        //     {item.value}
                                                        // </IonCheckbox>
                                                    )
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}
