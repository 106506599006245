import { IonIcon } from "@ionic/react"
import { AppColor } from "types"
import { getButtonColor } from "utils"

export const OutlineButton = (
    {   text, 
        customBorderColor, 
        onClickHandler,
        icon,
    }: 
    {   text: string, 
        customBorderColor: AppColor,
        onClickHandler?: () => void,
        icon?: string
    }
) => {
    const buttonStyles = {
        border: `solid 2px ${getButtonColor(customBorderColor)}`,
        color: getButtonColor(customBorderColor),
    }

    return (
        <button 
            onClick={onClickHandler}
            className="w-full text-[0.9rem] px-4 py-2 font-bold rounded-md flex justify-center items-center gap-1 cursor-pointer"
            style={buttonStyles}
        >
            { icon && <IonIcon icon={icon} className="text-[18px]" /> }
            {text}
        </button>
    )
}