export const ImagotipoGondolaCold = () => {
    return (
        <svg width="24" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1508 1.25H13.9453V10.7812H15.1508V1.25Z" fill="#183555" stroke="#183555" />
            <path d="M18.3587 4.04893L14.291 6.60059L14.9316 7.62176L18.9993 5.07011L18.3587 4.04893Z" fill="#183555" stroke="#183555" />
            <path d="M10.6769 4.05279L10.0361 5.07385L14.1034 7.62622L14.7441 6.60515L10.6769 4.05279Z" fill="#183555" stroke="#183555" />
            <path d="M15.1508 21.7188H13.9453V31.25H15.1508V21.7188Z" fill="#183555" stroke="#183555" />
            <path d="M14.9296 24.8718L14.2891 25.8929L18.3567 28.4446L18.9973 27.4234L14.9296 24.8718Z" fill="#183555" stroke="#183555" />
            <path d="M14.1077 24.8746L10.04 27.4263L10.6806 28.4474L14.7483 25.8958L14.1077 24.8746Z" fill="#183555" stroke="#183555" />
            <path d="M9.4567 18.4172L1.1875 23.1569L1.78695 24.2027L10.0561 19.4631L9.4567 18.4172Z" fill="#183555" stroke="#183555" />
            <path d="M2.59006 17.9229L2.02246 18.9863L6.25862 21.2473L6.82623 20.1838L2.59006 17.9229Z" fill="#183555" stroke="#183555" />
            <path d="M6.02878 20.8471L5.83691 25.645L7.04142 25.6932L7.23328 20.8953L6.02878 20.8471Z" fill="#183555" stroke="#183555" />
            <path d="M27.2126 8.24216L18.9434 12.9818L19.5428 14.0277L27.812 9.28801L27.2126 8.24216Z" fill="#183555" stroke="#183555" />
            <path d="M21.9868 6.80571L21.7949 11.6036L22.9994 11.6518L23.1913 6.85387L21.9868 6.80571Z" fill="#183555" stroke="#183555" />
            <path d="M22.7746 11.2647L22.207 12.3281L26.4432 14.5891L27.0108 13.5256L22.7746 11.2647Z" fill="#183555" stroke="#183555" />
            <path d="M2.35071 7.46183L1.68555 8.46716L9.63441 13.7264L10.2996 12.7211L2.35071 7.46183Z" fill="#183555" stroke="#183555" />
            <path d="M6.45158 6.33244L6.33594 11.1328L7.54105 11.1618L7.65669 6.36147L6.45158 6.33244Z" fill="#183555" stroke="#183555" />
            <path d="M6.58997 10.7491L2.21777 12.7343L2.71614 13.8319L7.08834 11.8467L6.58997 10.7491Z" fill="#183555" stroke="#183555" />
            <path d="M19.4181 18.7522L18.7529 19.7576L26.7018 25.0168L27.367 24.0115L19.4181 18.7522Z" fill="#183555" stroke="#183555" />
            <path d="M26.3009 18.6967L21.9287 20.6819L22.4271 21.7795L26.7993 19.7943L26.3009 18.6967Z" fill="#183555" stroke="#183555" />
            <path d="M21.476 21.3695L21.3604 26.1699L22.5655 26.199L22.6811 21.3986L21.476 21.3695Z" fill="#183555" stroke="#183555" />
            <path d="M14.6084 22.4139L9.28027 19.3359V13.184L14.6084 10.1061L19.9366 13.184V19.3359L14.6084 22.4139ZM10.4857 18.6408L14.6084 21.0196L18.7311 18.6408V13.8792L14.6084 11.5004L10.4857 13.8792V18.6408Z" fill="#183555" stroke="#183555" />
        </svg>
    )
}