export const IconDynamicDashboard = () => {
    return (
        <svg width="188" height="143" viewBox="0 0 188 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_254_38)">
                <path d="M143.086 47.4823H127.433V124.703H143.086V47.4823Z" fill="#183555" />
                <path d="M143.704 47.4823H128.051V124.703H143.704V47.4823Z" fill="#183555" />
                <path d="M178.59 142.941H9.41003C4.62586 142.941 0.740723 139.048 0.740723 134.272V9.61022C0.740723 4.83408 4.63388 0.940918 9.41003 0.940918H178.59C183.374 0.940918 187.259 4.83408 187.259 9.61022V134.264C187.259 139.048 183.366 142.933 178.59 142.933V142.941ZM9.41003 3.14838C5.84598 3.14838 2.94818 6.04618 2.94818 9.61022V134.264C2.94818 137.828 5.84598 140.733 9.41806 140.733H178.598C182.162 140.733 185.068 137.836 185.068 134.264V9.61022C185.068 6.04618 182.17 3.14035 178.598 3.14035H9.41003V3.14838Z" fill="#183555" />
                <path d="M41.7434 47.3618H69.8946C69.8946 62.806 57.1876 75.513 41.7434 75.513C26.2992 75.513 13.5923 62.806 13.5923 47.3618C13.5923 31.9176 26.2992 19.2026 41.7434 19.2026C44.6493 19.2026 47.539 19.6522 50.3004 20.5351L41.7434 47.3538V47.3618Z" fill="#183555" />
                <path d="M48.2935 42.6499L57.0912 15.9034C62.6941 17.7497 67.5827 21.3057 71.0584 26.0818C74.5342 30.85 76.4205 36.5974 76.4446 42.4973L48.2935 42.6499Z" fill="#183555" />
                <path d="M121.429 89.8093H105.776V124.711H121.429V89.8093Z" fill="#183555" />
                <path d="M164.735 74.0601H149.082V124.703H164.735V74.0601Z" fill="#183555" />
                <path d="M88.7182 7.92456H86.3101V135.179H88.7182V7.92456Z" fill="#183555" />
                <path d="M73.9801 91.222H22.3335V93.6302H73.9801V91.222Z" fill="#183555" />
                <path d="M73.9801 106.474H22.3335V108.882H73.9801V106.474Z" fill="#183555" />
                <path d="M73.9801 121.725H22.3335V124.133H73.9801V121.725Z" fill="#183555" />
            </g>
            <defs>
                <clipPath id="clip0_254_38">
                    <rect width="186.518" height="142" fill="white" transform="translate(0.740723 0.940918)" />
                </clipPath>
            </defs>
        </svg>
    )
}
