import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SaveUserInformationPayload } from 'interfaces';

interface UserState {
    uid: string | null,
    email: string | null,
    username: string | null,
    role: string | null,
    vendorCode: string | null,
}

const initialState: UserState = {
    uid: null,
    email: null,
    username: null,
    role: null,
    vendorCode: null,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        saveUserInformation: (state, action: PayloadAction<SaveUserInformationPayload>) => {
            state.uid = action.payload.uid;
            state.email = action.payload.email;
            state.username = action.payload.username;
            state.role = action.payload.role;
            state.vendorCode = action.payload.vendorCode;
        },
        deleteUserInformation: (state) => {
            state.uid = null;
            state.email = null;
            state.username = null;
            state.role = null;
            state.vendorCode = null;
        }
    },
})

// Action creators are generated for each case reducer function
export const 
{ 
    saveUserInformation,
    deleteUserInformation,
} = userSlice.actions