import { IonContent, IonPage, IonSpinner } from "@ionic/react"

export const LoadingPage = () => {
    return (
        <IonPage>
            <IonContent>
                <div className="w-full h-screen flex justify-center items-center">
                    <IonSpinner name="crescent" color="primary" className="w-[50px] h-[50px]" />
                </div>
            </IonContent>
        </IonPage>
    )
}