import { SearchFilterItem } from "interfaces";
import { AppColor } from "types";

export function getButtonColor(colorKey: AppColor): string {
    switch (colorKey) {
        case "primary":
            return "var(--ion-color-primary)";
        case "secondary":
            return "var(--ion-color-secondary)";
        case "tertiary":
            return "var(--ion-color-tertiary)";
        case "success":
            return "var(--ion-color-success)";
        case "warning":
            return "var(--ion-color-warning)";
        case "danger":
            return "var(--ion-color-danger)";
        case "light":
            return "var(--ion-color-light)";
        case "medium":
            return "var(--ion-color-medium)";
        case "dark":
            return "var(--ion-color-dark)";
        default:
            return "var(--ion-color-primary)";
    }
}

function convertirDistancia(numero: number) {
    if (numero >= 1000) {
        let kilometros = numero / 1000;
        return kilometros.toLocaleString("es-SV", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + " Kilómetros";
    } else {
        return numero.toLocaleString("es-SV", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }) + " Metros";
    }
}

export function getOrderDistance(trackingDetails: any) {
    let distance: string = "";

    if (Array.isArray(trackingDetails)) {
        if (trackingDetails.length === 1 && trackingDetails[0].distance) {
            distance = convertirDistancia(trackingDetails[0].distance);
        } else if (trackingDetails.length === 2 && trackingDetails[1].distance) {
            distance = convertirDistancia(trackingDetails[1].distance);
        }
    }

    return distance;
}

export function getSearchFilters(reportArray: any[], fieldName: string) {
    const uniqueNames = new Set<string>(reportArray.map(obj => obj[fieldName]));

    return Array.from(uniqueNames, item => ({
        value: item,
        label: item,
        checked: true,
        visible: true
    }));
}

export function getSearchFiltersValues(reportArray: any[], fieldName: string) {
    const uniqueNames = new Set<string>(reportArray.map(obj => obj[fieldName]));

    return Array.from(uniqueNames);
}

export function calculateSellerFulfillmentPercentage(assignatedClients: number, visitedClients: number): number {
    if (assignatedClients > 0) {
        if (visitedClients > assignatedClients) {
            return 100;
        } else {
            return Math.ceil(((visitedClients / assignatedClients) * 100))
        }

    } else {
        return 0;
    }
}