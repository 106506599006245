import BirthdaysPremier from 'assets/img/app/birthdays-premier.jpg';

export const DedicatoryTab = ({ data }: { data: any[] }) => {
    return (
        <div className="col-span-6 grid grid-cols-2 gap-4 pb-8"> 
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <h1 className="text-primary font-bold text-4xl italic">¡MUCHAS FELICIDADES Y ÉXITOS EN TU DÍA!</h1>
                    <h3 className="text-xl font-bold">DE TODA LA FAMILIA PREMIER</h3>
                </div>

                <h2 className="text-2xl text-secondary font-bold">Cumpleañeros del día: </h2>
                
                <div className="border rounded-md p-2">
                    {
                        data.map((item: any, index: number) => (
                            <div key={index}>
                                <p className="text-xl font-bold">{item.name} {item.last_name}</p>
                                <p className="text-lg text-primary">{item.message}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className="w-full flex justify-end">
                {
                    <img className="h-[450px]" src={data[0].image ?? BirthdaysPremier} alt="Imagen del cumpleañero" />
                }
            </div>
        </div>
    )
}
