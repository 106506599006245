import { IonIcon } from "@ionic/react";
import React from "react";
import { search } from 'ionicons/icons';

export const SearchField = (
    {
        onChangeHandler,
        reference
    }:
        {
            onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void,
            reference?: React.RefObject<HTMLInputElement>
        }
) => {
    return (
        <div className="w-full flex items-center relative">
            <input
                type="text"
                id="search-field"
                name="search-field"
                placeholder={"Buscar"}
                onChange={onChangeHandler}
                ref={reference}
                className="w-full text-mid text-primary ps-4 pe-10 py-[6px] border border-solid border-tertiary rounded-full focus:outline-none focus:border-primary"
            />

            <IonIcon icon={search} className="bg-light p-2 rounded-full absolute right-0 cursor-pointer" />
        </div>
    )
}
