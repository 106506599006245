export const IconOrders = () => {
    return (
        <svg width="170" height="143" viewBox="0 0 170 143" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_254_31)">
                <path d="M169.987 102.033L168.975 99.2362L156.194 103.847L136.817 43.8839C135.736 40.9884 132.494 39.4712 129.568 40.5025L121.745 43.3286L110.648 5.73629L110.619 5.63713C109.429 2.45402 105.859 0.778187 102.646 1.90863L52.7675 19.8669L48.0772 4.85375L47.7896 3.94146L18.3781 0.272461L18.0112 3.22749L45.5287 6.66841L79.3826 115.092C74.3055 117.244 70.7357 122.271 70.7357 128.122C70.7357 135.926 77.082 142.272 84.8861 142.272C92.6901 142.272 99.0365 135.926 99.0365 128.122C99.0365 127.953 99.0167 127.785 99.0068 127.616L169.987 102.033ZM93.4934 13.6891L79.5115 18.8257L77.7068 14.056L91.877 8.94914L93.4934 13.6891ZM74.9104 15.0674L77.7762 22.6434L97.2516 15.4839L94.6734 7.9476L103.648 4.71492C105.313 4.12986 107.178 4.98266 107.822 6.62875L118.948 44.3301L66.3428 63.3098L53.6699 22.7128L74.9203 15.0575L74.9104 15.0674ZM116.301 56.2593L97.0136 63.2205L94.4156 56.3585L113.792 49.3676L116.301 56.2692V56.2593ZM67.2253 66.1557L91.6093 57.36L95.2584 67.0085L120.108 58.0442L116.588 48.3462L130.57 43.2989C131.968 42.803 133.515 43.5368 134.001 44.8557L153.387 104.849L98.5704 124.612C97.0037 118.503 91.4705 113.982 84.8861 113.982C83.9837 113.982 83.0913 114.071 82.2385 114.239L67.2253 66.1656V66.1557ZM84.8861 139.298C78.7281 139.298 73.7105 134.29 73.7105 128.122C73.7105 121.954 78.7182 116.946 84.8861 116.946C91.054 116.946 96.0617 121.954 96.0617 128.122C96.0617 134.29 91.054 139.298 84.8861 139.298Z" fill="#183555" />
                <path d="M25.369 45.4606H7.40088V48.4354H25.369V45.4606Z" fill="#183555" />
                <path d="M44.0708 65.7987H19.0522V68.7736H44.0708V65.7987Z" fill="#183555" />
                <path d="M39.7672 83.7769H0.0131836V86.7517H39.7672V83.7769Z" fill="#183555" />
            </g>
            <defs>
                <clipPath id="clip0_254_31">
                    <rect width="169.974" height="142" fill="white" transform="translate(0.0131836 0.272461)" />
                </clipPath>
            </defs>
        </svg>
    )
}